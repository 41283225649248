<template>
    <div class="px-3">
        <v-row>
            <v-col cols="4">
                <h1 class="pl-8 title">{{ $t('LBL_CURRENT_ENTITY') }} {{ record ? identifierName() : '' }}</h1>
            </v-col>
            <v-col class="text-right">
                <v-btn
                        class="mx-2"
                        color="primary"
                        v-on:click="openEditPopup()"
                        v-show="editSupported"
                >
                    {{$t('LBL_EDIT_ENTITY')}}
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <div v-for="(fieldsName, blockName) in record.blocks" v-bind:key="blockName">

                        <v-row class="pl-8">
                            <v-col class="title">{{blockName}}</v-col>
                        </v-row>

                        <div v-for="(fieldName, id) in fieldsName" v-bind:key="id">

                            <div v-for="(fieldLabel, fieldName2) in record.editLabels" v-bind:key="fieldName2">

                                <div v-if="fieldName == fieldName2">

                                    <v-row class="pl-8">
                                        <v-col cols="4">{{fieldLabel}}</v-col>
                                        <v-col>{{record.record[fieldLabel]}}</v-col>
                                    </v-row>

                                </div>
                            </div>

                        </div>

                    </div>
                </v-card>
            </v-col>

            <v-col cols="6">
                <related-tabs-show :id="id"></related-tabs-show>
            </v-col>
        </v-row>

        <v-dialog v-model="edit" persistent max-width="600px">
            <create-address-book @close="closeHandler" :key="someKey" :id="id" :formStartValues="fieldStartValues"></create-address-book>
  <!--          
            <create-page @close="edit = false" :id="id" :formStartValues="fieldStartValues"></create-page>
  -->          
        </v-dialog>
        <notification></notification>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import CreateAddressBook from "@/components/CreateAddressBook";
    //import CreatePage from "@/components/CreatePage";
    import Notification from "@/components/Notification";
    import RelatedTabsShow from "@/components/RelatedTabsShow";
    import showRecordMixin from "../mixins/showRecordMixin";

    export default {
        mixins: [showRecordMixin],
        name: "EntityShow",
        props: ['id'],
        components: {CreateAddressBook, Notification, RelatedTabsShow},
        //components: {CreatePage, Notification, RelatedTabsShow},
        data() {
            return {
                moduleName: this.$route.name.split('-')[0],
                entity: {},
                someKey: 1,
                related: {module: this.$route.name.split('-')[0], id: this.id},
                fieldStartValues: {}
            }
        },
        computed: {
          editSupported() {
            if(this.moduleName == 'AddressBook'){
              return  true;
            } else {
              return  false;
            }
            
          },
          ...mapState(['record', 'module'])
        },
        methods: {
            identifierName() {
                var identifierNameLabel = this.record.record.identifierName ? this.record.record.identifierName.label : "";
                var identifierNameValue = this.record.record[identifierNameLabel] ? this.record.record[identifierNameLabel] : "";
                return identifierNameValue;
            },
            openEditPopup() {
                var editLabels = this.record.editLabels;
                for (var prop in editLabels) {
                    if (prop != 'id' && prop != 'assigned_user_id') {
                        var fieldName = editLabels[prop];
                        this.fieldStartValues[prop] = this.getPicklistValue(prop, this.record.record[fieldName]);
                    }
                }
                this.someKey = Math.random();
                this.edit = true;
            },
            getPicklistValue(name, val) {
                var fields = this.module.fields;
                var curField;
                var finalValue = null;
                for (var prop in fields) {
                    if (fields[prop].name == name) {
                        curField = fields[prop].type;
                    }
                }
                if (curField && curField.picklistValues) {
                    curField.picklistValues.forEach(function(value) {
                        if (val == value.label) {
                            finalValue = value.value;
                        }
                    });
                } else {
                    return val;
                }
                if (finalValue) {
                    return finalValue;
                } else {
                    return val;
                }
            },
            closeHandler() {
                this.edit = false;
                this.someKey = Math.random();
            }

        },
        created() {
            this.$store.dispatch('getModuleDescription', this.moduleName);
            this.$store.dispatch('fetchRelatedModules', this.related);
            this.$store.dispatch('fetchRecordModule', this.related);
            this.$store.dispatch('fetchBlocksFields', this.related);
        },
    }
</script>

<style scoped>

</style>
