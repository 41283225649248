<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{module.label}}
        <v-spacer></v-spacer>
        <div class="mr-2 my-2">
          <v-btn color="primary"  v-show="createSupported" @click="createRecordViewWeb">{{$t('LBL_CREATE_WEB_WAYBILL')}}</v-btn>
        </div>
        <!--
        <v-spacer></v-spacer>
        <div class="mr-2 my-2">
          <v-btn color="primary"  v-show="createSupported" @click="create = true">{{$t('LBL_CREATE_WAYBILL')}}</v-btn>
        </div>
        -->
        <v-spacer></v-spacer>
        <json-excel
                class   = "btn btn-default"
                :data   = "records"
                :fields = "jsonLabels"
                worksheet = "My Worksheet"
                name    = "Waybills.xls">
          <v-btn class="my-2" color="primary">{{$t('LBL_EXPORT_WAYBILLS')}}</v-btn>
        </json-excel>
        <v-spacer></v-spacer>
        <v-text-field
                v-model="search"
                append-icon="mdi-search"
                :label="$t('Search (at least 5 characters)')"
                single-line
                hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
              :headers="headers"
              :items="records"
              :search="search"
              :loading="loading"
              loading-text="Loading... Please wait"
              @click:row="selectRecord($event)"
              :style="{ cursor: 'pointer'}"
              :footer-props="{
                itemsPerPageText: $t('Items per page'),
                pageText: '{0}-{1} ' + $t('of') + ' {2}',
                itemsPerPageOptions:[10,15,25,50],
              }"
              :options.sync="options"
              :server-items-length="totalRecords"
              
      >
     
        <template v-slot:item.id="{ item }">
          <v-icon
            small
            class="mr-1 ml-2"
            @click="onPrintClick(item.id, $event)"
          >
            mdi-printer
          </v-icon>
          <v-icon
            small
            class=""
            @click="onDownloadClick(item.id, $event)"
          >
            mdi-tray-arrow-down
          </v-icon>
        </template> 
     
        <template v-slot:body.append>
          <tr>
            
            <td colspan="2">
              <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"

                      transition="scale-transition"
                      offset-y
                      min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                          v-model="dateRangeText"
                          :label="$t('Choose Dates')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-on="on"
                          :style="{ width: '220px'}"
                  ></v-text-field>
                </template>
                <v-date-picker :locale="$i18n.locale" :first-day-of-week="1" v-model="createDate" no-title scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="$refs.menu2.save(createDate=[])">{{$t('Cancel')}}</v-btn>
                  <v-btn text color="primary" @click="$refs.menu2.save(createDate)">{{$t('OK')}}</v-btn>
                </v-date-picker>
              </v-menu>
<!--              <v-text-field v-model="createDate" type="text" :label="$t('Choose Date')"></v-text-field>-->
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
      <v-row justify="center">
        <v-dialog v-model="create" persistent max-width="600px">
          <!-- <waybills-create-page @close="create = false"></waybills-create-page> -->
          <waybills-create-page :key="someKey" @close="closeHandler"></waybills-create-page>
        </v-dialog>
      </v-row>
    <notification></notification>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  import JsonExcel from 'vue-json-excel';
  import WaybillsCreatePage from "@/components/WaybillsCreatePage";
  import Notification from "@/components/Notification";
  import EventService from "@/services/EventService";
  
  //import printjs from 'print-js';
  import * as Printjs from "print-js";


export default {
  name: 'Waybills',
  components: {
    Notification,
    JsonExcel, WaybillsCreatePage
  },

  data: () => ({
    search: '',
    create: false,
    create_web: false,
    createDate: [],
    menu2: false,
    someKey: 1,
    options: {itemsPerPage: 15},
    records: [],
    totalRecords: 0,
    loading:true,
    headers: [],
    jsonLabels: {},
    createRecordDateStart: false,
    createRecordDateEnd: false,
  }),
  methods: {
    closeHandler(){
      this.create = false;
      this.someKey = Math.random();
    },
    selectRecord(event) {
      if (this.viewSupported) {
        var id = event.id;
        this.$router.push({ name: 'waybill-show', params: { id: id } })
      }
    },
    createRecordViewWeb() {
      if (this.createSupported) {
        this.$router.push({ name: 'waybill-edit', params: { id: '' } })
      }
    },
    getDataFromApi () {
        //this.loading = true
        var label = 'WayBill';
        var name = 'WayBill';

        var filter;
        var search;
        if(this.search && this.search.length < 5){
          return false;
        } else {
          search = this.search;
        }
        this.loading = true;
        /* eslint-disable no-console */
        console.log(this.options);
        console.log(this.search);
        /* eslint-enable no-console */
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        var numberPage = Number(page) - 1;
        EventService.fetchRecords(name, label, filter, numberPage, itemsPerPage, sortBy, sortDesc, search, this.createRecordDateStart, this.createRecordDateEnd)
        .then(response => {
          this.loading = false;
          /* eslint-disable no-console */
          console.log(response);
          /* eslint-enable no-console */
          this.totalRecords = response.data.result.count;
          //this.records = response.data.result.records;
          this.records = response.data.result.recordsOrig;
          
          var headers = [];  
          for (var key in response.data.result.headersKeyName) {
            var obj = {};
            if(key == 'id'){
              obj.text = 'Действия';
              obj.value = key;
              obj.sortable = false;
              headers.unshift(obj);
            } else {
              obj.text = response.data.result.headersKeyName[key];
              obj.value = key;
              headers.push(obj);
            }
          }
                        
          this.headers = headers;
          
          var jsonLabels={};
          for (var prop in response.data.result.editLabels) {
              jsonLabels[prop] = response.data.result.editLabels[prop];
          }   
          this.jsonLabels = jsonLabels;    
           
        })
        .catch(error => {
          /* eslint-disable no-console */
          console.log(error);
          /* eslint-enable no-console */
        })
        
        
    },
    onPrintClick(id, event){
      if(event){
        event.preventDefault()
        event.stopImmediatePropagation()
      }
      Printjs("https://mbelab.com/crm/a5ws.php?u3="+id+"&t=1&mode=getFile");
    },
    onDownloadClick(id, event){
      if(event){
        event.preventDefault()
        event.stopImmediatePropagation()
      }
      window.location.href = "https://mbelab.com/crm/a5ws.php?u3="+id+"&t=1&mode=getFile";
    },

  },
  
  mounted() {
    this.getDataFromApi();
  }, 
  created() {
    this.$store.dispatch('getModuleDescription', 'WayBill');
    //this.$store.dispatch('fetchWaybills', {page: 1, filter: {}});
  },
  
  computed: {
    createSupported() {
      return this.$store.getters.isRecordCreatable(this.module.name);
    },
    viewSupported() {
      return this.$store.getters.isRecordViewable(this.module.name);
    },
    dateRangeText () {
      return this.createDate.join(' ~ ')
    },
    ...mapState(['module', 'waybills'])
  },
  watch: {
    createDate: function (val) {
      var dateStart;
      var dateEnd;
      if(val[0] && val[1]){
        dateStart = new Date(val[0]);
        dateEnd = new Date(val[1]);
        if(dateStart > dateEnd){
          dateStart = val[1];
          dateEnd = val[0];
        } else {
          dateStart = val[0];
          dateEnd = val[1];
        }
      } else if(val[0]){
        dateStart = val[0];
        dateEnd = val[0];
      } else {
        dateStart = false;
        dateEnd = false;
      }

      this.createRecordDateStart = dateStart;
      this.createRecordDateEnd = dateEnd;
      this.getDataFromApi();
      //this.$store.commit('SET_CREATE_DATE_FILTER', val);
    },
    options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
    },
    search: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
    },
  }
};
</script>

<style lang="css">
.v-label.theme--light{
  color: #00000045;
}
</style>
