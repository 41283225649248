<template>
    <v-footer
            color="primary lighten-1"
            padless
    >
        <v-row
                justify="center"
                no-gutters
        >
            <v-col
                    class="primary lighten-2 py-4 text-center white--text"
                    cols="12"
            >
                {{ new Date().getFullYear() }} — <strong>{{companyTitle}}</strong>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: "Footer",
        computed: mapState(['companyTitle']),
    }
</script>

<style scoped>

</style>