<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                {{module.label}}
                <v-spacer></v-spacer>
                <div class="my-2">
                    <v-btn color="primary" @click="create = true" v-show="createSupported">{{$t('LBL_CREATE_ENTITY')}}</v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="mdi-search"
                        :label="$t('Search (at least 5 characters)')"
                        single-line
                        hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                    :headers="headers"
                    :items="records"
                    :search="search"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    @click:row="selectEntity($event)"
                    :style="{ cursor: 'pointer'}"
                    :footer-props="{
                      itemsPerPageText: $t('Items per page'),
                      pageText: '{0}-{1} ' + $t('of') + ' {2}',
                      itemsPerPageOptions:[10,15,25,50],
                    }"
                    :options.sync="options"
                    :server-items-length="totalRecords"
            ></v-data-table>
        </v-card>
        <v-row justify="center">
            <v-dialog v-model="create" persistent max-width="600px">
                <create-page @close="create = false"></create-page>
            </v-dialog>
        </v-row>
        <notification></notification>
    </v-container>
</template>
<script>
    import {mapState} from "vuex";
    import CreatePage from "@/components/CreatePage";
    import Notification from "@/components/Notification";
    import EventService from "@/services/EventService";

    export default {
        name: "AddressBooks",
        components: {
            Notification,
            CreatePage
        },
        data: () => ({
            search: '',
            create: false,
            options: {itemsPerPage: 15},
            records: [],
            totalRecords: 0,
            loading:true,
            headers: [],
        }),
        created() {
            this.$store.dispatch('getModuleDescription', this.$route.name);
            this.$store.dispatch('fetchRecords', {module: this.$route.name, label: this.$route.name, page: 0, filter: {}});
        },
        methods: {
            selectEntity(event) {
                if (this.viewSupported) {
                    const id = event.id;
                    const name = this.$route.name + '-show';
                    this.$router.push({ name: name, params: { id: id } })
                }
            },
            getDataFromApi () {

                var label = 'AddressBook';
                var name = 'AddressBook';

                var filter;
                var search;
                if(this.search && this.search.length < 5){
                  return false;
                } else {
                  search = this.search;
                }
                this.loading = true;
                /* eslint-disable no-console */
                console.log(this.options);
                console.log(this.search);
                /* eslint-enable no-console */
                const { sortBy, sortDesc, page, itemsPerPage } = this.options;
                var numberPage = Number(page) - 1;
                EventService.fetchRecords(name, label, filter, numberPage, itemsPerPage, sortBy, sortDesc, search)
                .then(response => {
                  this.loading = false;
                  /* eslint-disable no-console */
                  console.log(response);
                  /* eslint-enable no-console */
                  this.totalRecords = response.data.result.count;
                  //this.records = response.data.result.records;
                  this.records = response.data.result.recordsOrig;
                  var headers = [];
                  for (var key in response.data.result.headersKeyName) {
                    var obj = {};
                    if(key != 'id'){
                      obj.text = response.data.result.headersKeyName[key];
                      obj.value = key;
                    }
                    headers.push(obj);
                  }
                  this.headers = headers;                   
                })
                .catch(error => {
                  /* eslint-disable no-console */
                  console.log(error);
                  /* eslint-enable no-console */
                })
                
                
            },
        },
        mounted() {
          this.getDataFromApi();
        }, 
        computed: {
            createSupported() {
                if (this.module.name == 'AddressBook') {
                    return false;
                }
                if (this.module.name) {
                    return this.$store.getters.isRecordCreatable(this.module.name);
                } else {
                    return false;
                }
            },
            viewSupported() {
                if (this.module.name) {
                    return this.$store.getters.isRecordViewable(this.module.name);
                } else {
                    return false;
                }
            },
            ...mapState(['module', 'records'])
        },
        watch: {
          options: {
              handler () {
                this.getDataFromApi()
              },
              deep: true,
          },
          search: {
              handler () {
                this.getDataFromApi()
              },
              deep: true,
          },
        }
    }
</script>

<style scoped>

</style>
