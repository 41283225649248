<template>
	<v-card>
		<v-card-title>
			<span class="headline">{{$t('LBL_NEW_WAYBILL')}}</span>
		</v-card-title>
		<v-card-text>
			<v-container>
				<v-form ref="createRecordForm" v-model="formValidity">
									
					<div v-for="(fieldsName, blockName) in blocks" v-bind:key="blockName">
						
						<div v-if="blockName != 'Системная информация'">

							<v-row>
								<v-col class="title">{{blockName}}</v-col>
							</v-row>

							<div v-for="(fieldName, index) in fieldsName" v-bind:key="index">

								<div v-for="(value, key) in $store.getters.getEditableFields" :key="key">

									<v-row v-if="fieldName == value.name">

										<v-col cols="12" class="px-0" v-if="key == 6">
											<v-row>
												<v-col cols="10">
													<v-autocomplete
													v-model="searchModel"
													:items="entries"
													:loading="isLoading"
													:search-input.sync="search"
													color="white"
													hide-selected
													item-text="label"
													item-value="id"
													:placeholder="getSenderLabel"
													prepend-icon="mdi-database-search"
													:return-object="true"></v-autocomplete>
												</v-col>
												<v-col cols="2">
													<div class="v-input--selection-controls">
														<v-tooltip top>
															<template v-slot:activator="{ on }">
																<v-simple-checkbox v-on="on" v-model="searchModelActive"></v-simple-checkbox>
															</template>
															<span>{{$t('Save to Address Book')}}</span>
														</v-tooltip>													
													</div>
												</v-col>
											</v-row>
										</v-col>

										<v-col cols="12" class="px-0" v-if="key == 16">
											<v-row>
												<v-col cols="10">
													<v-autocomplete
													v-model="searchRecipModel"
													:items="entriesRecip"
													:loading="isLoadingRecip"
													:search-input.sync="searchRecip"
													color="white"
													hide-selected
													item-text="label"
													item-value="id"
													:placeholder="getRecipientFieldLabel"
													prepend-icon="mdi-database-search"
													:return-object="true"></v-autocomplete>
												</v-col>
												<v-col cols="2">
													<div class="v-input--selection-controls">
														<v-tooltip top>
															<template v-slot:activator="{ on }">
																<v-simple-checkbox v-on="on" v-model="searchRecipModelActive"></v-simple-checkbox>
															</template>
															<span>{{$t('Save to Address Book')}}</span>
														</v-tooltip>
													</div>											
												</v-col>
											</v-row>
										</v-col>

										<v-col cols="12" class="px-0" v-if="dadataLocations[value.name]">
											<v-row>
												<v-col cols="12">
													<v-autocomplete
													v-model="dadataModels"
													:items="dadataItems[dadataLocations[value.name]]"
													v-on:keyup="searchDadata(dadataLocations[value.name], $event)"
													color="white"
													hide-selected
													item-text="name"
													item-value="name"
													:placeholder="$t('Search in DB')"
													prepend-icon="mdi-database-search"
													:return-object="true"
													no-filter></v-autocomplete>
												</v-col>
											</v-row>
										</v-col>

										<v-text-field
										v-if="value.type.name == 'string'"
										:label="value.mandatory ? value.label + ' *' : value.label"
										:name="value.name"
										:rules="formValidations[value.name]"
										value="formValues[value.name]"
										v-model="formValues[value.name]"></v-text-field>

										<v-text-field
										v-else-if="value.type.name == 'integer'"
										:label="value.mandatory ? value.label + ' *' : value.label"
										:name="value.name"
										:rules="formValidations[value.name]"
										value="formValues[value.name]"
										v-model="formValues[value.name]"></v-text-field>

										<!-- <v-text-field
										v-else-if="value.type.name == 'phone'"
										:label="value.mandatory ? value.label + ' *' : value.label"
										:name="value.name"
										:rules="formValidations[value.name]"
										value="formValues[value.name]"
										v-model="formValues[value.name]"></v-text-field> -->

										<v-text-field
										v-else-if="value.type.name == 'phone'"
										:label="value.mandatory ? '+7(999)999-99-99 ' + value.label + ' *' : '+7(999)999-99-99 ' + value.label"
										:name="value.name"
										:rules="formValidations[value.name]"
										v-mask="'+#(###)###-##-##'"
										value="formValues[value.name]"
										v-model="formValues[value.name]"
										v-on:keyup="formValues[value.name] = chekNumberPhone(formValues[value.name], $event)"
										v-on:blur="formValues[value.name] = chekNumberPhone(formValues[value.name], $event)"></v-text-field>

										<v-text-field
										v-else-if="value.type.name == 'email'"
										:label="value.mandatory ? value.label + ' *' : value.label"
										:name="value.name"
										:rules="formValidations[value.name]"
										value="formValues[value.name]"
										v-model="formValues[value.name]"></v-text-field>

										<v-text-field
										v-else-if="value.type.name == 'currency'"
										:label="value.mandatory ? value.label + ' *' : value.label"
										:name="value.name"
										:rules="formValidations[value.name]"
										value="formValues[value.name]"
										v-model="formValues[value.name]"
										suffix="руб."></v-text-field>
										

										<v-menu
										v-else-if="value.type.name == 'date'"
										ref="menu1"
										v-model="menu1"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
										full-width
										max-width="290px"
										min-width="290px"
										>								
										<!-- <template v-if="value.name != 'expected_delivery_date'"> -->
										<!-- <template v-show="!showFieldDate">-->
											<template v-slot:activator="{ on }">
												<v-text-field
												v-model="dateFormatted"
												:label="value.mandatory ? value.label + ' *' : value.label"
												:name="value.name"
												:rules="formValidations[value.name]"
												persistent-hint
												prepend-icon="mdi-calendar"
												@blur="date = parseDate(dateFormatted)"
												v-on="on"
												readonly></v-text-field>
											</template>
									<!--		</template>	-->
									<!--	</template> -->
										<v-date-picker v-if="showFieldDate" v-model="date" no-title @input="menu1 = false"></v-date-picker>
									</v-menu>

									<v-select
									v-else-if="value.type.name == 'picklist' && value.name != 'select_client_tarif'"
									:items="value.type.picklistValues"
									item-text="label"
									item-value="value"
									value="formValues[value.name]"
									v-model="formValues[value.name]"
									:label="value.mandatory ? value.label + ' *' : value.label"
									:rules="formValidations[value.name]"
									:name="value.name"></v-select>

									<v-textarea
									v-else-if="value.type.name == 'text' && value.name != 'special_customer_mark'"
									:name="value.name"
									:label="value.mandatory ? value.label + ' *' : value.label"
									:rules="formValidations[value.name]"
									value="formValues[value.name]"
									v-model="formValues[value.name]"></v-textarea>

									<v-checkbox
									v-else-if="value.type.name == 'boolean' && value.name != 'create_by_2021'"
									:name="value.name"
									:label="value.mandatory ? value.label + ' *' : value.label"></v-checkbox>

								</v-row>

							</div>

						</div>

					</div>

				</div>

			</v-form>
		</v-container>
		<small>*{{$t('LBL_REQUIRED')}}</small>
	</v-card-text>
	<v-card-actions>
		<v-spacer></v-spacer>
		<v-btn color="blue darken-1" text @click="resetForm()">{{$t('Cancel')}}</v-btn>
		<v-btn color="blue darken-1" text @click="saveForm()" :disabled="!formValidity">{{$t('Save')}}</v-btn>
	</v-card-actions>
	<v-dialog
	v-model="loading"
	hide-overlay
	persistent
	width="300"
	v-if="loading">
	<v-card
	color="primary"
	dark>
	<v-card-text>
		{{$t('Please stand by')}}
		<v-progress-linear
		indeterminate
		color="white"
		class="mb-0"
		></v-progress-linear>
	</v-card-text>
</v-card>
</v-dialog>
<notification></notification>
</v-card>
</template>

<script>
import { mapState } from 'vuex';
import EventService from "@/services/EventService";
import Notification from "./Notification";

export default {
	name: "WaybillsCreatePage",
	components: {Notification},
	props: {
		id: {
			type: String,
			default: ''
		},
		formStartValues: {
			type: Object,
			// required: true,
			default: function () {
				return {}
			}
		}
	},
	data() {
		return {
			requiredAddressFields: ['fio', 'phone'],
			loading: false,
			message: '',
			notification: false,
			formValues: {},
			formValidations: {},
			maxValues: 190,
			minValues: 5,
			formValidity: false,
			date: new Date().toISOString().substr(0, 10),
			dateFormatted: '',
			showFieldDate: true,
			preChangedDate: '',
			menu1: false,
			searchModel: null,
			searchModelActive: false,
			searchRecipModel: null,
			searchRecipModelActive: false,
			isLoading: false,
			isLoadingRecip: false,
			search: null,
			searchRecip: null,
			entries: [],
			entriesRecip: [],
			mapping: [],
			blocks: null,
			dadataFields: [],
			dadataModels: {},
			dadataItems: {},
			dadataLocations: {'wrecipientcountry' : 'wrecipientaddress', 'wsendercountry' : 'wsenderaddress'}
		}
	},
	created() {
		this.loading = true;
		EventService.fetchBlocks('WayBill', null)
		.then(response => {
			/* eslint-disable no-console */
			console.log(response.data.result.record);
			/* eslint-enable no-console */
			this.blocks = response.data.result.record;
			this.blocks["Параметры посылки"][0] = "wtypeofcargo";
			this.blocks["Параметры посылки"][1] = "wlength";
			this.blocks["Параметры посылки"][2] = "wwidth";
			this.blocks["Параметры посылки"][3] = "wheight";
			this.blocks["Параметры посылки"][4] = "wpackageqty";
			this.blocks["Параметры посылки"][5] = "wweight";
			this.blocks["Параметры посылки"][6] = "wparceldescription";			
		}).catch(error => {
			/* eslint-disable no-console */
			console.log(error);
			/* eslint-enable no-console */
		})
		.finally(() => this.loading = false);
		for (var prop in this.module.fields) {
			var name = this.module.fields[prop].name;
			var value = this.module.fields[prop].type.defaultValue ? this.module.fields[prop].type.defaultValue : this.module.fields[prop].default;
			var validation = [];
			if (this.module.fields[prop].mandatory) {
				
				const rule =
				v => !!v || this.$t('LBL_FIELD_REQUIRED');

				validation.push(rule);
			}
			if (this.module.fields[prop].type.name != 'text') {
				let rule;
				if (this.module.fields[prop].type.name == 'currency') {
					const regexp = /^\$?([0-9]{1,3} ([0-9]{3} )*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/;
					rule = v => !v || regexp.test(v) || this.$t('LBL_INVALID_CHARACTERS');
				} else if (this.module.fields[prop].type.name == 'integer') {
					const regexp = /^\d+$/;
					rule = v => regexp.test(v) || this.$t('LBL_INVALID_CHARACTERS');
				} else if (this.module.fields[prop].type.name == 'phone') {
					const regexp = /^(\+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2})$/;
					rule = v => regexp.test(v) || this.$t('LBL_INVALID_PHONE');
				} else {
					rule =
					v => (v || '').length <= this.maxValues ||
					(this.$t('LBL_MAX_CHARACTERS') + this.maxValues);
				}
				validation.push(rule);
			} else {
				// const rule =
				// v => (v || '').length > this.minValues ||
				// (this.$t('LBL_MIN_CHARACTERS') + this.minValues);

				// validation.push(rule);
			}
			
			
			if (this.module.fields[prop].name == 'wtakedate') {
				const rule =
				value => {
					var newDateSelect = this.parseDate(value);
					var today = new Date();
					today.setHours(0);
					today.setMinutes(0);
					today.setSeconds(0);
					today.setMilliseconds(0);
					if(new Date(newDateSelect) < today){
						//this.dateFormatted = this.formatDate(this.preChangedDate);
						//this.date = this.preChangedDate;
						return this.$t('LBL_DATE_CANNOT_BE_LATER_TODAY');
					} else {
						return true;
					}			
				}

				validation.push(rule);
			}
			
			if (this.module.fields[prop].type.name == 'email') {
				const rule =
				value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalid e-mail.'
				}

				validation.push(rule);
			}
			
			if (this.module.fields[prop].editable) {
				this.$set(this.formValues, name, value);
				this.$set(this.formValidations, name, validation);
			}

		}
		this.$set(this.formValues, 'recipientId', null);
		this.$set(this.formValues, 'senderId', null);
		if (Object.keys(this.formStartValues).length !== 0) {
			// this.formValues = this.formStartValues;
			this.formValues = Object.assign({}, this.formStartValues);
		}
		this.getDateDefault();
		this.getDaDataFields();
	},
	mounted() {
		const self = this;
		if (Object.keys(this.formStartValues).length !== 0) {
			// this.formValues = this.formStartValues;
			this.formValues = Object.assign({}, this.formStartValues);
		}
		EventService.fetchMapping(this.module.name)
		.then(response => {
			this.mapping = response.data.result.data.standartAddressFields;
		})
		.catch(error => {
			/* eslint-disable no-console */
			console.log(error);
			/* eslint-enable no-console */
		})
		if (this.formValues.wsendercontact){
			EventService.searchAddressBook(this.formValues.wsendercontact)
			.then(function(response) {
				self.formValues.senderId = response.data.result.data[0].id;
			})
		}
		if (this.formValues.wrecipientcontact){
			EventService.searchAddressBook(this.formValues.wrecipientcontact)
			.then(function(response) {
				self.formValues.recipientId = response.data.result.data[0].id;
			})
		}
	},
	watch: {
		dadataModels(after) {
			if (after.data) {
				for (const name in after.data) {
					this.formValues[name] = after.data[name];
				}
			}
		},
		formStartValues: function() {
			if (Object.keys(this.formStartValues).length !== 0) {
				// this.formValues = this.formStartValues;
				this.formValues = Object.assign({}, this.formStartValues);
			}
		},
		date () {
			this.preChangedDate = this.parseDate(this.dateFormatted); //For validation and retun old select
			this.dateFormatted = this.formatDate(this.date)
		},
		search (val) {
			if(val.length < 3) {
				return;
			}
			// Items have already been requested
			if (this.isLoading) return;
			this.isLoading = true;
			// Lazily load input items
			EventService.searchAddressBook(val)
			.then(res => {
				this.entries = res.data.result.data;
				/* eslint-disable no-console */
				console.log(this.entries);
				/* eslint-enable no-console */
			})
			.catch(err => {
				/* eslint-disable no-console */
				console.log(err);
				/* eslint-enable no-console */
			})
			.finally(() => (this.isLoading = false))
		},
		searchRecip (val) {
			if(val.length < 3) {
				return;
			}
			// Items have already been requested
			if (this.isLoadingRecip) return;
			this.isLoadingRecip = true;
			// Lazily load input items
			EventService.searchAddressBook(val)
			.then(res => {
				this.entriesRecip = res.data.result.data;
			})
			.catch(err => {
				/* eslint-disable no-console */
				console.log(err);
				/* eslint-enable no-console */
			})
			.finally(() => (this.isLoadingRecip = false))
		},
		searchModel(model) {
			const self = this;
			/* eslint-disable no-console */
			console.log(model);
			/* eslint-enable no-console */
			for (let field in this.mapping[0]) {
				let fieldName = this.mapping[0][field];
				self.formValues[fieldName] = model.data[field];
			}
			this.formValues.senderId = model.id;
		},
		searchRecipModel(model) {
			const self = this;
			for (let field in this.mapping[1]) {
				let fieldName = this.mapping[1][field];
				self.formValues[fieldName] = model.data[field];
			}
			this.formValues.recipientId = model.id;
		}
	},
	methods: {
		getDateDefault: function(){
      if(this.formValues['wtakedate']){
        this.dateFormatted = this.formatDate(this.formValues['wtakedate']);
				this.showFieldDate = false;
				this.$delete(this.formValidations, 'wtakedate');
      } else {
        this.dateFormatted = this.formatDate(new Date().toISOString().substr(0, 10));
      }
    },
		resetForm() {
			this.$refs.createRecordForm.reset();
			this.$refs.createRecordForm.resetValidation();
			this.$emit('close');
		},
		saveForm() {
			var self = this;
			this.loading = true;
			this.saveAddressBook();
			
			var wtakedateEl = document.getElementsByName('wtakedate')[0];
			if(wtakedateEl){
				var wtakedate = wtakedateEl.value;
				this.formValues.wtakedate = this.parseDate (wtakedate);				
			}
			
			const values = this.formValues;
			
			delete values.recipientId;
			delete values.senderId;
			delete values.wpstatus;
			delete values.wrecivedby;
			delete values.house;
			delete values.block;
			delete values.block_type;
			for (const name in values) {
				for (const obj in this.module.fields) {
					if (this.module.fields[obj].name === name && !this.module.fields[obj].editable) {
						delete values[name];
					}
				}
			}

			if (typeof this.formValues.wcustomer == 'object') {
				values.wcustomer = this.formValues.wcustomer.value;
			} else {
				if (this.formValues.wcustomer.startsWith("11x")){
					values.wcustomer = this.formValues.wcustomer;
				} else {
					values.wcustomer = '11x' + this.formValues.wcustomer;
				}
			}
			EventService.saveRecord(this.module.name, values, this.id).then(function(response){
				self.create = false;
				self.loading = false;
				/* eslint-disable no-console */
				console.log(response);
				/* eslint-enable no-console */
				if (response.data.result.code) {
					self.$store.commit('SET_NOTIFICATION', true);
					self.$store.commit('SET_MESSAGE', response.data.result.message);
				} else {
					self.$store.commit('SET_NOTIFICATION', true);
					self.$store.commit('SET_MESSAGE', self.$t('Record added successfully'));
					self.$emit('close');
					if (!self.id || self.id == '') {
						self.$refs.createRecordForm.reset();
						self.$refs.createRecordForm.resetValidation();
						if (self.module.name == 'WayBill') {
							self.$store.commit('ADD_WAYBILL', response.data.result.record);
							self.$router.push({ name: 'waybill-show', params: { id: response.data.result.record.id } })
						} else {
							self.$store.commit('ADD_RECORD', response.data.result.record);
						}
					} else {
						self.$store.dispatch('fetchRecordModule', {module: self.module.name, id: self.id});
					}
				}
			})
			.catch(function(error){
				/* eslint-disable no-console */
				console.log(error);
				/* eslint-enable no-console */
				self.loading = false;
				self.$store.commit('SET_NOTIFICATION', true);
				self.$store.commit('SET_MESSAGE', self.$t('There was an error in adding record') + error.message);
			});
		},
		formatDate (date) {
			if (!date) return null;
			
			const [year, month, day] = date.split('-');
			return `${day}-${month}-${year}`;
		},
		parseDate (date) {
			if (!date) return null
				const [day, month, year] = date.split('-')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},
		saveAddressBook() {
			const self = this;
			this.mapping.forEach(function(map, index) {
				let record = {};
				let id = '';
				if (index > 0 && !self.searchRecipModelActive) {
					return;
				} else if(index < 1 && !self.searchModelActive) {
					return;
				}
				for (let field in map) {
					let crmField = map[field];
					if (field != 'account_id') {
						record[field] = self.formValues[crmField];
						if (self.requiredAddressFields.includes(field) && !self.formValues[crmField]) {
							return;
						}
					}
				}
				if (index > 0) {
					id = self.formValues.recipientId;
				} else {
					id = self.formValues.senderId;
				}
				EventService.saveRecord('AddressBook', record, id)
				.then(function(response) {
					/* eslint-disable no-console */
					console.log(response);
					/* eslint-enable no-console */
				})
				.catch(function(error) {
					/* eslint-disable no-console */
					console.log(error);
					/* eslint-enable no-console */
				})
			});
		},
		getDaDataFields () {
			const self = this
			EventService.fetchDaDataFields(this.module.name)
			.then(response => {
				this.dadataFields = response.data.result
				this.dadataFields.forEach(function(field) {
						// this.dadataModels[field] = null
						self.dadataItems[field] = []
					})
			})
			.catch(error => {
				/* eslint-disable no-console */
				console.log(error)
				/* eslint-enable no-console */
			})
		},
		searchDadata (field, e) {
			const value = e.target.value
			if (value.length > 3) {
				EventService.fetchDaDataSearch(this.module.name, value, field)
				.then(response => {
					const res = [];
					const result = response.data.result;
					for (const obj in result) {
						res.push({ name: obj, data: result[obj]})
					}
					const objRes = { ...this.dadataItems };
					objRes[field] = res;
					this.$set(this, 'dadataItems', objRes);

				})
				.catch(error => {
					/* eslint-disable no-console */
					console.log(error)
					/* eslint-enable no-console */
				})
			}
		},
		chekNumberPhone (field, event) {
			var number = event.target.value;
			var numberCode = number.substr(1, 1);
			if(numberCode == 8){
				return number.substring(0, 1) + '7' + number.substring(2);
			} else if(numberCode != 8 && numberCode != 7 && numberCode > 0){
				var newNumber = number.substring(0, 1) + '7' + number.substring(1);
				newNumber = newNumber.replace(/\(/, '');
				newNumber = newNumber.replace(/\)/, '');
				newNumber = newNumber.replace(/-/g, '');
				newNumber = newNumber.substring(0, 2) + '(' + newNumber.substring(2,5) + ')' + newNumber.substring(5,8)+ '-' + newNumber.substring(8,10)+ '-' + newNumber.substring(10);
				return newNumber;
			} else {
				return number;
			}
		}
	},
	computed: {
		computedDateFormatted () {
			return this.formatDate(this.date)
		},
		items() {
			return this.entries;
		},
		getRecipientFieldLabel() {
			return this.formValues.wrecipientcontact ? this.formValues.wrecipientcontact : this.$t('Start typing');
		},
		getSenderLabel() {
			return this.formValues.wsendercontact ? this.formValues.wsendercontact : this.$t('Start typing');
		},
		...mapState(['module']),
	},
}
</script>

<style scoped>

</style>
