var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.module.label)+" "),_c('v-spacer'),_c('div',{staticClass:"mr-2 my-2"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.createSupported),expression:"createSupported"}],attrs:{"color":"primary"},on:{"click":_vm.createRecordViewWeb}},[_vm._v(_vm._s(_vm.$t('LBL_CREATE_WEB_WAYBILL')))])],1),_c('v-spacer'),_c('json-excel',{staticClass:"btn btn-default",attrs:{"data":_vm.records,"fields":_vm.jsonLabels,"worksheet":"My Worksheet","name":"Waybills.xls"}},[_c('v-btn',{staticClass:"my-2",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$t('LBL_EXPORT_WAYBILLS')))])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":_vm.$t('Search (at least 5 characters)'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{style:({ cursor: 'pointer'}),attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading... Please wait","footer-props":{
                itemsPerPageText: _vm.$t('Items per page'),
                pageText: '{0}-{1} ' + _vm.$t('of') + ' {2}',
                itemsPerPageOptions:[10,15,25,50],
              },"options":_vm.options,"server-items-length":_vm.totalRecords},on:{"click:row":function($event){return _vm.selectRecord($event)},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-1 ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.onPrintClick(item.id, $event)}}},[_vm._v(" mdi-printer ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onDownloadClick(item.id, $event)}}},[_vm._v(" mdi-tray-arrow-down ")])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({style:({ width: '220px'}),attrs:{"label":_vm.$t('Choose Dates'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"first-day-of-week":1,"no-title":"","scrollable":"","range":""},model:{value:(_vm.createDate),callback:function ($$v) {_vm.createDate=$$v},expression:"createDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.createDate=[])}}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.createDate)}}},[_vm._v(_vm._s(_vm.$t('OK')))])],1)],1)],1)])]},proxy:true}])})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.create),callback:function ($$v) {_vm.create=$$v},expression:"create"}},[_c('waybills-create-page',{key:_vm.someKey,on:{"close":_vm.closeHandler}})],1)],1),_c('notification')],1)}
var staticRenderFns = []

export { render, staticRenderFns }