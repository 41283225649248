<template>
    <div class="px-3">
        <v-row class="ModuleHeader">
            <v-col cols="12" xs="6" sm="6">
                <h1 class="pl-8 title">{{ $t('LBL_CURRENT_WAYBILL') }} {{ record ? identifierName() : '' }}</h1>
            </v-col>
            <v-col cols="12" xs="6" sm="6" class="text-right">
                        <v-btn class="mx-2" color="primary" v-on:click="openEditPopup()" v-show="editSupported" :disabled="isLocked">{{$t('LBL_EDIT_WAYBILL')}}</v-btn>
                        <v-btn class="ml-2" color="primary" v-on:click="downloadPdfWbOper()" v-show="printedWbOperSupported">{{$t('LBL_PRINT_WAYBILL_OPERATOR')}}</v-btn>
                    <!--    
                        <v-btn class="ml-2" color="primary" v-on:click="downloadPdf()">{{$t('LBL_PRINT_WAYBILL')}}(old)</v-btn>
                      -->  
                        <v-btn class="ml-2" color="primary" v-on:click="downloadWayBillTempl_1_Pdf()"><v-icon>mdi-tray-arrow-down</v-icon>1</v-btn>
                        <v-btn class="ml-2" color="primary" v-on:click="printTempl_1_Pdf()"><v-icon>mdi-printer</v-icon>1 экз</v-btn>
                        <v-btn class="ml-2" color="primary" v-on:click="printTempl_2_Pdf()"><v-icon>mdi-printer</v-icon>3 экз</v-btn>

            </v-col>
        </v-row>

        <v-row class="ModuleContent">

            <v-col>
                <v-card>

                    <div v-for="(fieldsName, blockName) in record.blocks" v-bind:key="blockName">

                        <v-row class="pl-8">
                           <v-col class="title">{{blockName}}</v-col>
                        </v-row>

                        <div v-for="(fieldName, id) in fieldsName" v-bind:key="id">

                            <div v-for="(fieldLabel, fieldName2) in record.editLabels" v-bind:key="fieldName2">

                                <div v-if="fieldName == fieldName2">

                                    <v-row v-if="fieldName == 'wurloperator'" class="pl-8">
                                        <v-col v-if="wagentMode()" cols="4">{{fieldLabel}}</v-col>
                                        <v-col v-if="wagentMode()"><v-btn text :href="getFieldValue(fieldLabel)" color="primary">{{getFieldValue(fieldLabel)}}</v-btn></v-col>
                                    </v-row>
                                    
                                    <v-row v-else-if="fieldName == 'create_by_2021'" class="pl-8">    
                                    </v-row>
                                    <v-row v-else-if="fieldName == 'wisexpress'" class="pl-8">  
                                    </v-row>

                                    <v-row v-else class="pl-8">
                                        <v-col cols="4">{{fieldLabel}}</v-col>
                                        <v-col>{{getFieldValue(fieldLabel)}}</v-col>
                                    </v-row>
                                    
                                </div>
                            </div>

                        </div>
                                
                    </div>

                </v-card>

            </v-col>

            <v-col cols="6">
                    <v-tabs
                        v-model="tab"
                        background-color="deep-purple accent-4"
                        class="elevation-2"
                        dark
                        :centered="false"
                        :grow="false"
                        :vertical="false"
                        :right="false"
                    >
                
                       <v-tabs-slider></v-tabs-slider>

                        <v-tab
                            v-for="(value, key) in record.relatedModules"
                            :key="key"
                            :href="`#tab-${key}`"
                        >
                        {{$t(value.name)}}
                        </v-tab>

                        <v-tab-item
                            v-for="(value, key) in messages"
                            :key="key"
                            :value="'tab-' + key"
                        >

                        <v-card
                                flat
                                tile
                        >
                            
                            <div v-if="record.relatedModules[key] && record.relatedModules[key].name == 'ModComments'">
                                <v-textarea
                                        class="px-4"
                                        name="comment"
                                        label="Введите свой комментарий"
                                        v-model="comment"
                                ></v-textarea>
                                
                                <v-card-actions :style="{ float: 'right'}">
                                    <v-btn color="primary" v-on:click="sendComment()">{{$t('LBL_SEND')}}</v-btn>
                                </v-card-actions>
                            </div>

                            <v-card-text v-if="!value || value.length < 1">{{ $t('LBL_NO_RECORDS') }}</v-card-text>
                            <v-card-text v-else>
                                <div v-if="record.relatedModules[key] && record.relatedModules[key].name == 'ModComments'">
                                    <v-list-item class="px-0" v-for="comment in value" :key="comment.id">
                                      <v-list-item-content>
                                        <v-list-item-title class="primary--text font-weight-regular">{{comment.customer.label}}</v-list-item-title>
                                        <v-list-item-subtitle class="caption">
                                          {{comment.createdtime}}
                                        </v-list-item-subtitle>
                                         {{comment.commentcontent}}
                                      </v-list-item-content>
                                    </v-list-item>
                                </div>
                                <div v-else-if="record.relatedModules[key] && record.relatedModules[key].name == 'History'">
                                    <div v-for="(history, index) in value" :key="index">
                                            <div v-for="(item, name) in history" :key="name">
                                                <div v-if="typeof item == 'object'">
                                                    <v-list-item class="px-0">
                                                        <v-list-item-content v-if="item.updateStatus == 'updated'">
                                                              <v-list-item-title class="primary--text font-weight-regular">
                                                                  {{name}}
                                                              </v-list-item-title>
                                                              <v-list-item-subtitle class="font-weight-bold">
                                                                {{ $t(item.updateStatus) }}
                                                              </v-list-item-subtitle>
                                                              <v-list-item-subtitle>
                                                              {{ $t('LBL_FROM') }} "<strong>{{item.previous}}</strong>"
                                                              </v-list-item-subtitle>
                                                              <v-list-item-subtitle>
                                                              {{ $t('LBL_TO') }} "<strong>{{item.current}}</strong>"
                                                              </v-list-item-subtitle>
                                                              <v-list-item-subtitle class="caption">
                                                              {{history.modifiedtime}}
                                                              </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <!-- Тут не хватае блока на случай, если updateStatus == 'created'-->
                                                    </v-list-item>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <v-btn class="" color="primary" v-on:click="downloadFullTraking()">{{$t('LBL_FULL_TRAKING')}}</v-btn>
                                            
                                </div>
                                <div v-else>
                                    <v-list-item three-line v-for="(record, index) in value" :key="index">
                                        <v-list-item-content>
                                            <v-list-item-title class="primary--text font-weight-regular">{{record.filename}}</v-list-item-title>
                                            <v-list-item-subtitle class="caption">
                                                {{record.id}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </div>
                            </v-card-text>

                            <v-card-actions :style="{ float: 'right'}">
                            <v-btn
                                    large
                                    :color="getColorBtn(key)"
                                    @click="loadMore(key)"
                                    :disabled="maxLength(key) === 0"
                            >
                                {{$t('LBL_LOAD_MORE')}}</v-btn>
                    
                             </v-card-actions>

                        </v-card>

                    </v-tab-item>

                </v-tabs>
            </v-col>
        </v-row>
        <v-dialog v-model="edit" persistent max-width="600px">
            <!-- <waybills-create-page @close="edit = false" :id="id" :formStartValues="fieldStartValues"></waybills-create-page> -->
            <waybills-create-page :key="someKey" @close="closeHandler" :id="id" :formStartValues="fieldStartValues"></waybills-create-page>
        </v-dialog>
        <notification></notification>
    </div>

</template>

<script>
    import EventService from "@/services/EventService";
    import { mapState } from 'vuex';
    import WaybillsCreatePage from "@/components/WaybillsCreatePage";
    import Notification from "@/components/Notification";
    import showRecordMixin from "../mixins/showRecordMixin";
    
    import * as Printjs from "print-js";

    export default {
        mixins: [showRecordMixin],
        name: "WaybillShow",
        props: ["id"],
        components: {WaybillsCreatePage, Notification},
        data() {
            return {
                ticket: {},
                tab: null,
                comment: "",
                related: {module: "WayBill", id: this.id},
                fieldStartValues: {},
                templateId: 11,
                url: "https://mbelab.com/crm",
                someKey: 1,
                wtoclient: "",
                wurloperator: "",
            }
        },
        created() {
          this.$store.dispatch('fetchRelatedModules', this.related);
          this.$store.dispatch('fetchRecordModule', this.related);
          this.$store.dispatch('getModuleDescription', 'WayBill');
          this.$store.dispatch('fetchBlocksFields', this.related);
          
          EventService.getDataPrintedWbOperSupported(this.related.id, this.related.module)
          .then(response => {
            var resJson = response.request.response;
            var res = JSON.parse(resJson);
            this.wtoclient = res.result.result.wtoclient;
            this.wurloperator = res.result.result.wurloperator;
          })
          .catch(error => {
            /* eslint-disable no-console */
            console.log(error);
            /* eslint-enable no-console */
          })
          
        },
        mounted() {
          this.$nextTick(function () {
            this.setPaddingTopModuleContent();
          });
        },
        computed: {
            messages () {
                return this.$store.getters.getRecordsMain
            },
            isLocked() {
                return false;
            },
            editSupported() {
                return this.$store.getters.isRecordEditable(this.module.name) && this.record.record['Статус посылки'] == 'Проект';
            },
            printedWbOperSupported() {
              if(this.wtoclient == 'yes'){
                return true;
              } else return false;
            },
            ...mapState(['record', 'module'])
        },
        methods: {
            setPaddingTopModuleContent: function(){
              var topModuleHeader = document.getElementsByClassName('ModuleHeader')[0].offsetHeight;
              if(topModuleHeader > 0){
                document.getElementsByClassName('ModuleContent')[0].style.paddingTop = topModuleHeader;
              }
            },
            closeHandler() {
                this.edit = false;
                this.someKey = Math.random();
            },
            sendComment() {
                var commentParams = {};
                commentParams.commentcontent = this.comment;
                commentParams.parentId = '';
                commentParams.related_to = this.id;
                var tabArr = this.tab.split('-');
                var key = tabArr[1];
                var self = this;
                EventService.addCommentPost(commentParams)
                .then(response => {
                    var result = response.data.result;
                    result.main = true;
                    self.$store.commit('SET_NOTIFICATION', true);
                    self.$store.commit('SET_MESSAGE', self.$t('Comment send successfully'));
                    this.comment = "";
                    this.$store.commit('ADD_RELATED_RECORD', [result, key]);
                    this.$store.commit('ADD_RELATED_RECORD_MAIN', [result, key]);
                })
                    .catch(error => {
                        self.$store.commit('SET_NOTIFICATION', true);
                        self.$store.commit('SET_MESSAGE', this.$t('There was an error in sending comment: ' + error.message));
                        /* eslint-disable no-console */
                        console.log(error);
                        /* eslint-enable no-console */
                    });
            },
            addDocument(file) {
                var record = file.data.result.record;
                var index = this.getIndexByName('Documents');
                if (index >= 0) {
                    record.main = true;
                    this.$store.commit('ADD_RELATED_RECORD', [record, index]);
                    this.$store.commit('ADD_RELATED_RECORD_MAIN', [record, index]);
                }
            },
            maxLength (key) {
                return this.$store.getters.getRecordsFilter[key] ? this.$store.getters.getRecordsFilter[key].length : 0;
            },
            getColorBtn (key) {
                if (this.maxLength(key) === 0) {
                    return '';
                } else {
                    return 'primary';
                }
            },
            loadMore(key) {
                this.$store.dispatch('loadRecords', key);
            },
            getIndexByName(name) {
                return this.record.relatedModules.findIndex(module => module.name == name);
            },
            openEditPopup() {
                var editLabels = this.record.editLabels;
                for (var prop in editLabels) {
                    if (prop != 'id' && prop != 'assigned_user_id') {
                        var fieldName = editLabels[prop];
                        this.fieldStartValues[prop] = this.getPicklistValue(prop, this.record.record[fieldName]);
                    }
                }
                this.someKey = Math.random();
                this.edit = true;
            },
            getPicklistValue(name, val) {
                var fields = this.module.fields;
                var curField;
                var finalValue = null;
                for (var prop in fields) {
                    if (fields[prop].name == name) {
                        curField = fields[prop].type;
                    }
                }
                if (curField && curField.picklistValues) {
                    curField.picklistValues.forEach(function(value) {
                        if (val == value.label) {
                            finalValue = value.value;
                        }
                    });
                } else {
                    return val;
                }
                if (finalValue) {
                    return finalValue;
                } else {
                    return val;
                }
            },
            identifierName() {
                var identifierNameLabel = this.record.record.identifierName ? this.record.record.identifierName.label : "";
                var identifierNameValue = this.record.record[identifierNameLabel] ? this.record.record[identifierNameLabel] : "";
                return identifierNameValue;
            },
            wagentMode() {
                var wagent_mode = this.record.record['Режим агента'] ? this.record.record['Режим агента'] : "";
                if (wagent_mode != 'Доставка агентом' && wagent_mode != '') {
                    return true;
                } else {
                    return false;
                }
                
            },
            getFieldValue(fieldLabel) {
                var fieldValue = typeof this.record.record[fieldLabel] == 'object' ? this.record.record[fieldLabel].label : this.record.record[fieldLabel];
                return fieldValue;

            },
            downloadPdf() {
                var recordId = this.id.split("x");
                window.open(this.url + "/modules/WayBill/download.php?module=" + this.related.module + "&record=" + recordId[1] + "&templateid=" + this.templateId + "&app=SUPPORT","_blank")
            },
            downloadPdfWbOper() {
                window.open(this.wurloperator,"_blank");
            },
            downloadFullTraking(){
              var url = 'https://mberussia.com/tracking/?invoicecode='+this.identifierName();
              window.open(url,"_blank");
            },
            downloadWayBillTempl_1_Pdf(){
              window.location.href = "https://mbelab.com/crm/a5ws.php?u3="+this.id+"&t=1&mode=getFile";
            },
            printTempl_1_Pdf(){
              Printjs("https://mbelab.com/crm/a5ws.php?u3="+this.id+"&t=1&mode=getFile");
            },
            printTempl_2_Pdf(){
              Printjs("https://mbelab.com/crm/a5ws.php?u3="+this.id+"&t=2&mode=getFile");
            }
            
        }
    }
</script>

<style lang="css">

.ModuleHeader{
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: white;
}

</style>
