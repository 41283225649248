<template>
    <div class="px-3">
          <v-row class="ModuleHeader">
            <v-col cols="12" md="3">
              <h1 class="title">{{$t('LBL_NEW_WAYBILL')}}</h1>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-center ModuleHeaderAction">
                <v-btn-toggle v-model="toggle_exclusive" mandatory>
                  <v-btn class="ModuleHeaderButtons" outlined color="primary">
                    {{$t('LBL_OPTIMA')}}
                  </v-btn>
                  <v-btn class="ModuleHeaderButtons" outlined color="primary">
                    {{$t('LBL_EXPRESS')}}
                  </v-btn>
                  <v-btn class="ModuleHeaderButtons" outlined color="primary">
                    {{$t('LBL_PRAYM')}}
                  </v-btn>
                  <v-btn class="ModuleHeaderButtons" outlined color="primary">
                    {{$t('LBL_DAY_TO_DAY')}}
                  </v-btn>
                </v-btn-toggle>
              </div>
              <!--
                        <v-btn class="mx-2" color="primary" v-on:click="openEditPopup()" v-show="editSupported" :disabled="isLocked">{{$t('LBL_EDIT_WAYBILL')}}</v-btn>
                        <v-btn class="ml-2" color="primary" v-on:click="downloadPdf()">{{$t('Save')}}</v-btn>
                 -->
            </v-col>
            <v-col cols="12" md="3">

            </v-col>
        </v-row>

        <v-row class="ModuleContent" justify="center">
            <v-form class="width100p" ref="createRecordForm" v-model="formValidity">
                <v-row>
                  <v-col cols="12" md="4" >
                    <v-card>
                      <!-- {{ setTarif }} -->
                      <div class="BlockContetnt">
                        <v-row>
                          <v-col class="title red--text py-0">Информация об отправителе</v-col>
                        </v-row>


                          <v-col cols="12" class="px-0 py-0">
                            <v-row >
                              <v-col cols="10" class="py-0">
                                <v-autocomplete
                                v-model="searchModel"
                                :items="getEntries"
                                :loading="isLoading"
                                v-on:keyup="searchSender($event)"
                                color="white"
                                hide-selected
                                item-text="label"
                                item-value="id"
                                :placeholder="getSenderLabel"
                                prepend-icon="mdi-database-search"
                                :return-object="true"
                                no-filter></v-autocomplete>
                              </v-col>
                              <v-col cols="2" class="py-0">
                                <div class="v-input--selection-controls">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-simple-checkbox v-on="on" v-model="searchModelActive"></v-simple-checkbox>
                                    </template>
                                    <span>{{$t('Save to Address Book')}}</span>
                                  </v-tooltip>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>

                          <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                            <v-text-field
                            v-if="value.name == 'wsendercontact'"
                            :label="value.mandatory ? value.label + ' *' : value.label"
                            :name="value.name"
                            :rules="formValidations[value.name]"
                            :class="hideBlockAddress(value.classGruop)"
                            value="formValues[value.name]"
                            v-model="formValues[value.name]"></v-text-field>
                          </div>

                          <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                            <v-text-field
                            v-if="value.name == 'wsendercompany'"
                            :label="value.mandatory ? value.label + ' *' : value.label"
                            :name="value.name"
                            :rules="formValidations[value.name]"
                            :class="hideBlockAddress(value.classGruop)"
                            value="formValues[value.name]"
                            v-model="formValues[value.name]"></v-text-field>
                          </div>

                          <v-row v-show="addressManuallySenderShow == 1">
                            <v-col cols="12" class="py-0">
                              <v-autocomplete
                              v-model="dadataModels"
                              :items="dadataItems[dadataLocations['wsendercountry']]"
                              v-on:keyup="searchDadata(dadataLocations['wsendercountry'], $event)"
                              color="white"
                              hide-selected
                              item-text="name"
                              item-value="name"
                              :placeholder="$t('Search in DB')"
                              prepend-icon="mdi-database-search"
                              :return-object="true"
                              no-filter></v-autocomplete>
                            </v-col>
                          </v-row>

                            <v-row>
                              <v-col cols="12" :class="'py-0'">
                                <v-row v-show="addressManuallySenderShow == 1">
                                  <v-col cols="12" :class="'py-0'">
                                    <div>
                                      <span @click="handlerHideBlockAddress('sender', 'show')"
                                      class='green-costum-text text-decoration-underline cursorPointer textLink'>
                                        {{$t('Fill in the address manually')}}
                                      </span>
                                    </div>
                                    <div class="py-2">
                                      <span class="theme--light v-label">{{$t('address')}}:</span>
                                      {{ setAddressTextSender }}
                                    </div>
                                  </v-col>
                                </v-row>
                                <v-row v-show="addressManuallySenderRollUpShow == 1">
                                  <v-col cols="12" md="6" :class="'py-0'">
                                    <span class="bold">{{$t('Enter Address')}}:</span>
                                  </v-col>
                                  <v-col cols="12" md="6" :class="'py-0 text-right'">
                                    <span
                                    @click="handlerHideBlockAddress('sender', 'hide')"
                                    class='green-costum-text text-decoration-underline cursorPointer textLink'>
                                      {{$t('Roll up')}}
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>



                            <v-row>
                              <v-col cols="12" md="6" :class="'py-0'">
                                <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                  <v-text-field
                                  v-if="value.name == 'wsendercountry'"
                                  :label="value.mandatory ? value.label + ' *' : value.label"
                                  :name="value.name"
                                  :rules="formValidations[value.name]"
                                  :class="hideBlockAddress(value.classGruop)"
                                  value="formValues[value.name]"
                                  v-model="formValues[value.name]"></v-text-field>
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" :class="'py-0'">
                                <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                  <v-text-field
                                  v-if="value.name == 'wsendergeography'"
                                  :label="value.mandatory ? value.label + ' *' : value.label"
                                  :name="value.name"
                                  :rules="formValidations[value.name]"
                                  :class="hideBlockAddress(value.classGruop)"
                                  value="formValues[value.name]"
                                  v-model="formValues[value.name]"></v-text-field>
                                </div>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" md="6" :class="'py-0'">
                                <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                  <v-text-field
                                  v-if="value.name == 'wsenderregion'"
                                  :label="value.label"
                                  :name="value.name"
                                  :rules="formValidations[value.name]"
                                  :class="hideBlockAddress(value.classGruop)"
                                  value="formValues[value.name]"
                                  v-model="formValues[value.name]"></v-text-field>
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" :class="'py-0'">
                                <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                  <v-text-field
                                  v-if="value.name == 'wsendercity'"
                                  :label="value.mandatory ? value.label + ' *' : value.label"
                                  :name="value.name"
                                  :rules="formValidations[value.name]"
                                  :class="hideBlockAddress(value.classGruop)"
                                  value="formValues[value.name]"
                                  v-model="formValues[value.name]"></v-text-field>
                                </div>
                              </v-col>
                            </v-row>


                          <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                            <v-textarea
                            v-if="value.name == 'wsenderaddress'"
                            :name="value.name"
                            :rows="'3'"
                            :label="value.mandatory ? value.label + ' *' : value.label"
                            :rules="formValidations[value.name]"
                            :class="hideBlockAddress(value.classGruop)"
                            value="formValues[value.name]"
                            v-model="formValues[value.name]"></v-textarea>
                          </div>

                          <v-row>
                            <v-col cols="12" md="5" :class="'py-0'">
                              <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                <v-text-field
                                v-if="value.name == 'wsenderflat'"
                                :label="value.mandatory ? value.label + ' *' : value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                :class="hideBlockAddress(value.classGruop)"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"></v-text-field>
                              </div>
                            </v-col>
                            <v-col cols="12" md="7" :class="'py-0'">
                              <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                <v-text-field
                                v-if="value.name == 'wsenderphone'"
                                :label="value.mandatory ?  value.label + ' *' :  value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                v-mask="getMaskPhoneSender"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"
                                v-on:focus="setIsMaskPhone(value.name)"
                                v-on:keyup="formValues[value.name] = chekNumberPhone(value.name, $event)"
                                v-on:blur="formValues[value.name] = chekNumberPhone(value.name, $event)"></v-text-field>
                              </div>
                            </v-col>
                          </v-row>


                          <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                            <v-textarea
                            v-if="value.name == 'wsenderinfo'"
                            :name="value.name"
                            :rows="'4'"
                            :label="value.mandatory ? value.label + ' *' : value.label"
                            :rules="formValidations[value.name]"
                            :class="hideBlockAddress(value.classGruop)"
                            value="formValues[value.name]"
                            v-model="formValues[value.name]"></v-textarea>
                          </div>

                      </div>



  <!--
                      <div v-for="(fieldsName, blockName) in blocks" v-bind:key="blockName">

                        <div v-if="blockName == 'Информация об отправителе'" class="BlockContetnt">

                          <v-row>
                            <v-col class="title red--text py-0">{{blockName}}</v-col>
                          </v-row>

                          <div v-for="(fieldName, index) in fieldsName" v-bind:key="index">

                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">


                              <v-row v-if="fieldName == value.name">

                                <v-col cols="12" class="px-0 py-0" v-if="fieldName == 'wrecipientcontact'">
                                  <v-row >
                                  <v-row>
                                    <v-col cols="10">
                                      <v-autocomplete
                                      v-model="searchRecipModel"
                                      :items="entriesRecip"
                                      :loading="isLoadingRecip"
                                      :search-input.sync="searchRecip"
                                      color="white"
                                      hide-selected
                                      item-text="label"
                                      item-value="id"
                                      :placeholder="getRecipientFieldLabel"
                                      prepend-icon="mdi-database-search"
                                      :return-object="true"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="2">
                                      <div class="v-input--selection-controls">
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on }">
                                            <v-simple-checkbox v-on="on" v-model="searchRecipModelActive"></v-simple-checkbox>
                                          </template>
                                          <span>{{$t('Save to Address Book')}}</span>
                                        </v-tooltip>
                                      </div>
                                    </v-col>
                                  </v-row>
                                  </v-row>
                                </v-col>

                                <v-col cols="12" class="px-0 py-0" v-if="dadataLocations[value.name]">
                                  <v-row v-show="addressManuallySenderShow == 1">
                                    <v-col cols="12" class="py-0">
                                      <v-autocomplete
                                      v-model="dadataModels"
                                      :items="dadataItems[dadataLocations[value.name]]"
                                      v-on:keyup="searchDadata(dadataLocations[value.name], $event)"
                                      color="white"
                                      hide-selected
                                      item-text="name"
                                      item-value="name"
                                      :placeholder="$t('Search in DB')"
                                      prepend-icon="mdi-database-search"
                                      :return-object="true"
                                      no-filter></v-autocomplete>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" :class="'py-0'">
                                      <v-row v-show="addressManuallySenderShow == 1">
                                        <v-col cols="12" :class="'py-0'">
                                          <div>
                                            <span @click="handlerHideBlockAddress('sender', 'show')"
                                            class='red--text text-decoration-underline cursorPointer textLink'>
                                              {{$t('Fill in the address manually')}}
                                            </span>
                                          </div>
                                          <div class="py-2">
                                            <span class="theme--light v-label">{{$t('address')}}:</span>
                                            {{ setAddressTextSender }}
                                          </div>
                                        </v-col>
                                      </v-row>
                                      <v-row v-show="addressManuallySenderRollUpShow == 1">
                                        <v-col cols="12" md="6" :class="'py-0'">
                                          <span class="bold">{{$t('Enter Address')}}:</span>
                                        </v-col>
                                        <v-col cols="12" md="6" :class="'py-0 text-right'">
                                          <span
                                          @click="handlerHideBlockAddress('sender', 'hide')"
                                          class='red--text text-decoration-underline cursorPointer textLink'>
                                            {{$t('Roll up')}}
                                          </span>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>

                                </v-col>


                               <v-text-field
                               v-if="value.type.name == 'string'"
                               :label="value.mandatory ? value.label + ' *' : value.label"
                               :name="value.name"
                               :rules="formValidations[value.name]"
                               :class="hideBlockAddress(value.classGruop)"
                               value="formValues[value.name]"
                               v-model="formValues[value.name]"></v-text-field>


                                <v-text-field
                                v-else-if="value.type.name == 'integer'"
                                :label="value.mandatory ? value.label + ' *' : value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                :class="hideBlockAddress(value.classGruop)"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"></v-text-field>

                                <v-text-field
                                v-else-if="value.type.name == 'phone'"
                                :label="value.mandatory ? '+7(999)999-99-99 ' + value.label + ' *' : '+7(999)999-99-99 ' + value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                v-mask="'+#(###)###-##-##'"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"
                                v-on:keyup="formValues[value.name] = chekNumberPhone(formValues[value.name], $event)"
                                v-on:blur="formValues[value.name] = chekNumberPhone(formValues[value.name], $event)"></v-text-field>

                                <v-text-field
                                v-else-if="value.type.name == 'email'"
                                :label="value.mandatory ? value.label + ' *' : value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"></v-text-field>

                                <v-text-field
                                v-else-if="value.type.name == 'currency'"
                                :label="value.mandatory ? value.label + ' *' : value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"
                                suffix="руб."></v-text-field>


                                <v-menu
                                v-else-if="value.type.name == 'date'"
                                ref="menu1"
                                v-model="menu1"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                full-width
                                max-width="290px"
                                min-width="290px">
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                  v-model="dateFormatted"
                                  :label="value.mandatory ? value.label + ' *' : value.label"
                                  :name="value.name"
                                  :rules="formValidations[value.name]"
                                  persistent-hint
                                  prepend-icon="mdi-calendar"
                                  @blur="date = parseDate(dateFormatted)"
                                  v-on="on"
                                  readonly></v-text-field>
                                </template>
                                <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
                              </v-menu>

                              <v-select
                              v-else-if="value.type.name == 'picklist'"
                              :items="value.type.picklistValues"
                              item-text="label"
                              item-value="value"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :rules="formValidations[value.name]"
                              :name="value.name"></v-select>

                              <v-textarea
                              v-else-if="value.type.name == 'text'"
                              :rows="'3'"
                              :name="value.name"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :rules="formValidations[value.name]"
                              :class="hideBlockAddress(value.classGruop)"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"></v-textarea>

                              <v-checkbox
                              v-else-if="value.type.name == 'boolean'"
                              :name="value.name"
                              :label="value.mandatory ? value.label + ' *' : value.label"></v-checkbox>

                              </v-row>

                            </div>
                          </div>
                        </div>
                      </div>

-->

                    </v-card>
                  </v-col>

                  <v-col cols="12" md="4" >
                    <v-card>

                      <div style="margin-left: -24px;margin-top: 250px; position: absolute;"
                      :class="'cursorPointer'"
                      @click="changeDataSenderByRecipient()"
                      >
                        <v-icon color="red" >mdi-swap-horizontal</v-icon>
                      </div>

                      <div class="BlockContetnt">
                        <v-row>
                          <v-col class="title red--text py-0">Информация о получателе</v-col>
                        </v-row>


                          <v-col cols="12" class="px-0 py-0">
                            <v-row>
                              <v-col cols="10" class="py-0">
                                <v-autocomplete
                                v-model="searchRecipModel"
                                :items="entriesRecip"
                                :loading="isLoadingRecip"
                                :search-input.sync="searchRecip"
                                color="white"
                                hide-selected
                                item-text="label"
                                item-value="id"
                                :placeholder="getRecipientFieldLabel"
                                prepend-icon="mdi-database-search"
                                :return-object="true"
                                no-filter></v-autocomplete>
                              </v-col>
                              <v-col cols="2" class="py-0">
                                <div class="v-input--selection-controls">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-simple-checkbox v-on="on" v-model="searchRecipModelActive"></v-simple-checkbox>
                                    </template>
                                    <span>{{$t('Save to Address Book')}}</span>
                                  </v-tooltip>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>

                          <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                            <v-text-field
                            v-if="value.name == 'wrecipientcontact'"
                            :label="value.mandatory ? value.label + ' *' : value.label"
                            :name="value.name"
                            :rules="formValidations[value.name]"
                            :class="hideBlockAddress(value.classGruop)"
                            value="formValues[value.name]"
                            v-model="formValues[value.name]"></v-text-field>
                          </div>

                          <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                            <v-text-field
                            v-if="value.name == 'wrecipientcompany'"
                            :label="value.mandatory ? value.label + ' *' : value.label"
                            :name="value.name"
                            :rules="formValidations[value.name]"
                            :class="hideBlockAddress(value.classGruop)"
                            value="formValues[value.name]"
                            v-model="formValues[value.name]"></v-text-field>
                          </div>

                          <v-row v-show="addressManuallyRecipientShow == 1">
                            <v-col cols="12" class="py-0">
                              <v-autocomplete
                              v-model="dadataModels"
                              :items="dadataItems[dadataLocations['wrecipientcountry']]"
                              v-on:keyup="searchDadata(dadataLocations['wrecipientcountry'], $event)"
                              color="white"
                              hide-selected
                              item-text="name"
                              item-value="name"
                              :placeholder="$t('Search in DB')"
                              prepend-icon="mdi-database-search"
                              :return-object="true"
                              no-filter></v-autocomplete>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" :class="'py-0'">
                              <v-row v-show="addressManuallyRecipientShow == 1">
                                <v-col cols="12" :class="'py-0'">
                                  <div>
                                    <span @click="handlerHideBlockAddress('recipient', 'show')"
                                    class='green-costum-text text-decoration-underline cursorPointer textLink'>
                                      {{$t('Fill in the address manually')}}
                                    </span>
                                  </div>
                                  <div class="py-2">
                                    <span class="theme--light v-label">{{$t('address')}}:</span>
                                    {{ setAddressTextRecipient }}
                                  </div>
                                </v-col>
                              </v-row>
                              <v-row v-show="addressManuallyRecipientRollUpShow == 1">
                                <v-col cols="12" md="6" :class="'py-0'">
                                  <span class="bold">{{$t('Enter Address')}}:</span>
                                </v-col>
                                <v-col cols="12" md="6" :class="'py-0 text-right'">
                                  <span
                                  @click="handlerHideBlockAddress('recipient', 'hide')"
                                  class='green-costum-text text-decoration-underline cursorPointer textLink'>
                                    {{$t('Roll up')}}
                                  </span>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>



                            <v-row>
                              <v-col cols="12" md="6" :class="'py-0'">
                                <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                  <v-text-field
                                  v-if="value.name == 'wrecipientcountry'"
                                  :label="value.mandatory ? value.label + ' *' : value.label"
                                  :name="value.name"
                                  :rules="formValidations[value.name]"
                                  :class="hideBlockAddress(value.classGruop)"
                                  value="formValues[value.name]"
                                  v-model="formValues[value.name]"></v-text-field>
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" :class="'py-0'">
                                <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                  <v-text-field
                                  v-if="value.name == 'wrecipientgeography'"
                                  :label="value.mandatory ? value.label + ' *' : value.label"
                                  :name="value.name"
                                  :rules="formValidations[value.name]"
                                  :class="hideBlockAddress(value.classGruop)"
                                  value="formValues[value.name]"
                                  v-model="formValues[value.name]"></v-text-field>
                                </div>
                              </v-col>
                            </v-row>


                            <v-row>
                              <v-col cols="12" md="6" :class="'py-0'">
                                <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                  <v-text-field
                                  v-if="value.name == 'wrecipientregion'"
                                  :label="value.label"
                                  :name="value.name"
                                  :rules="formValidations[value.name]"
                                  :class="hideBlockAddress(value.classGruop)"
                                  value="formValues[value.name]"
                                  v-model="formValues[value.name]"></v-text-field>
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" :class="'py-0'">
                                <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                  <v-text-field
                                  v-if="value.name == 'wrecipientcity'"
                                  :label="value.mandatory ? value.label + ' *' : value.label"
                                  :name="value.name"
                                  :rules="formValidations[value.name]"
                                  :class="hideBlockAddress(value.classGruop)"
                                  value="formValues[value.name]"
                                  v-model="formValues[value.name]"></v-text-field>
                                </div>
                              </v-col>
                            </v-row>


                          <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                            <v-textarea
                            v-if="value.name == 'wrecipientaddress'"
                            :name="value.name"
                            :rows="'3'"
                            :label="value.mandatory ? value.label + ' *' : value.label"
                            :rules="formValidations[value.name]"
                            :class="hideBlockAddress(value.classGruop)"
                            value="formValues[value.name]"
                            v-model="formValues[value.name]"></v-textarea>
                          </div>

                          <v-row>
                            <v-col cols="12" md="5" :class="'py-0'">
                              <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                <v-text-field
                                v-if="value.name == 'wrecipienflat'"
                                :label="value.mandatory ? value.label + ' *' : value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                :class="hideBlockAddress(value.classGruop)"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"></v-text-field>
                              </div>
                            </v-col>
                            <v-col cols="12" md="7" :class="'py-0'">
                              <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                                <v-text-field
                                v-if="value.name == 'wrecipientphone'"
                                :label="value.mandatory ?  value.label + ' *' :  value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                v-mask="getMaskPhoneRecipient"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"
                                v-on:focus="setIsMaskPhone(value.name)"
                                v-on:keyup="formValues[value.name] = chekNumberPhone(value.name, $event)"
                                v-on:blur="formValues[value.name] = chekNumberPhone(value.name, $event)"></v-text-field>
                              </div>
                            </v-col>
                          </v-row>


                          <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                            <v-textarea
                            v-if="value.name == 'wrecipientinfo'"
                            :name="value.name"
                            :rows="'4'"
                            :label="value.mandatory ? value.label + ' *' : value.label"
                            :rules="formValidations[value.name]"
                            :class="hideBlockAddress(value.classGruop)"
                            value="formValues[value.name]"
                            v-model="formValues[value.name]"></v-textarea>
                          </div>
                      </div>

    <!--

                      <div v-for="(fieldsName, blockName) in blocks" v-bind:key="blockName">

                        <div v-if="blockName == 'Информация о получателе'" class="BlockContetnt">

                          <v-row>
                            <v-col class="title red--text py-0">{{blockName}}</v-col>
                          </v-row>



                          <div v-for="(fieldName, index) in fieldsName" v-bind:key="index">

                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">

                              <v-row v-if="fieldName == value.name">

                                <v-col cols="12" class="px-0 py-0" v-if="fieldName == 'wrecipientcontact'">
                                  <v-row>
                                    <v-col cols="10" class="py-0">
                                      <v-autocomplete
                                      v-model="searchModel"
                                      :items="entries"
                                      :loading="isLoading"
                                      :search-input.sync="search"
                                      color="white"
                                      hide-selected
                                      item-text="label"
                                      item-value="id"
                                      :placeholder="getSenderLabel"
                                      prepend-icon="mdi-database-search"
                                      :return-object="true"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="2" class="py-0">
                                      <div class="v-input--selection-controls">
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on }">
                                            <v-simple-checkbox v-on="on" v-model="searchModelActive"></v-simple-checkbox>
                                          </template>
                                          <span>{{$t('Save to Address Book')}}</span>
                                        </v-tooltip>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col cols="12" class="px-0 py-0" v-if="dadataLocations[value.name]">
                                  <v-row v-show="addressManuallyRecipientShow == 1" >
                                    <v-col cols="12" class="py-0">
                                      <v-autocomplete
                                      v-model="dadataModels"
                                      :items="dadataItems[dadataLocations[value.name]]"
                                      v-on:keyup="searchDadata(dadataLocations[value.name], $event)"
                                      color="white"
                                      hide-selected
                                      item-text="name"
                                      item-value="name"
                                      :placeholder="$t('Search in DB')"
                                      prepend-icon="mdi-database-search"
                                      :return-object="true"
                                      no-filter></v-autocomplete>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col cols="12" :class="'py-0'">
                                      <v-row v-show="addressManuallyRecipientShow == 1">
                                        <v-col cols="12" :class="'py-0'">
                                          <div>
                                            <span @click="handlerHideBlockAddress('recipient', 'show')"
                                            class='red--text text-decoration-underline cursorPointer textLink'>
                                              {{$t('Fill in the address manually')}}
                                            </span>
                                          </div>
                                          <div class="py-2">
                                            <span class="theme--light v-label">{{$t('address')}}:</span>
                                            {{ setAddressTextRecipient }}
                                          </div>
                                        </v-col>
                                      </v-row>
                                      <v-row v-show="addressManuallyRecipientRollUpShow == 1">
                                        <v-col cols="12" md="6" :class="'py-0'">
                                          <span class="bold">{{$t('Enter Address')}}:</span>
                                        </v-col>
                                        <v-col cols="12" md="6" :class="'py-0 text-right'">
                                          <span
                                          @click="handlerHideBlockAddress('recipient', 'hide')"
                                          class='red--text text-decoration-underline cursorPointer textLink'>
                                            {{$t('Roll up')}}
                                          </span>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>

                                </v-col>

                                <v-text-field
                                v-if="value.type.name == 'string'"
                                :label="value.mandatory ? value.label + ' *' : value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                :class="hideBlockAddress(value.classGruop)"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"></v-text-field>

                                <v-text-field
                                v-else-if="value.type.name == 'integer'"
                                :label="value.mandatory ? value.label + ' *' : value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                :class="hideBlockAddress(value.classGruop)"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"></v-text-field>

                                <v-text-field
                                v-else-if="value.type.name == 'phone'"
                                :label="value.mandatory ? '+7(999)999-99-99 ' + value.label + ' *' : '+7(999)999-99-99 ' + value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                v-mask="'+#(###)###-##-##'"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"
                                v-on:keyup="formValues[value.name] = chekNumberPhone(formValues[value.name], $event)"
                                v-on:blur="formValues[value.name] = chekNumberPhone(formValues[value.name], $event)"></v-text-field>

                                <v-text-field
                                v-else-if="value.type.name == 'email'"
                                :label="value.mandatory ? value.label + ' *' : value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"></v-text-field>

                                <v-text-field
                                v-else-if="value.type.name == 'currency'"
                                :label="value.mandatory ? value.label + ' *' : value.label"
                                :name="value.name"
                                :rules="formValidations[value.name]"
                                value="formValues[value.name]"
                                v-model="formValues[value.name]"
                                suffix="руб."></v-text-field>


                                <v-menu
                                v-else-if="value.type.name == 'date'"
                                ref="menu1"
                                v-model="menu1"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                full-width
                                max-width="290px"
                                min-width="290px">
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                  v-model="dateFormatted"
                                  :label="value.mandatory ? value.label + ' *' : value.label"
                                  :name="value.name"
                                  :rules="formValidations[value.name]"
                                  persistent-hint
                                  prepend-icon="mdi-calendar"
                                  @blur="date = parseDate(dateFormatted)"
                                  v-on="on"
                                  readonly></v-text-field>
                                </template>
                                <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
                              </v-menu>
-->
<!--
                              <v-select
                              v-else-if="value.type.name == 'picklist'"
                              :items="value.type.picklistValues"
                              item-text="label"
                              item-value="value"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :rules="formValidations[value.name]"
                              :name="value.name"></v-select>
-->
<!--
                              <v-textarea
                              v-else-if="value.type.name == 'text'"
                              :name="value.name"
                              :rows="'3'"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :rules="formValidations[value.name]"
                              :class="hideBlockAddress(value.classGruop)"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"></v-textarea>

                              <v-checkbox
                              v-else-if="value.type.name == 'boolean'"
                              :name="value.name"
                              :label="value.mandatory ? value.label + ' *' : value.label"></v-checkbox>

                              </v-row>
                            </div>
                          </div>
                        </div>
                      </div>
-->
                    </v-card>
                  </v-col>


                  <v-col cols="12" md="4" >
                    <v-card>
                      <div class="BlockContetnt" style="padding-bottom: 14px;">
                        <v-row>
                          <v-col class="title red--text py-0">Общая Информация</v-col>
                        </v-row>
                        <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                          <v-menu
                          v-if="value.name == 'wtakedate'"
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field
                            v-model="dateFormatted"
                            :label="value.mandatory ? value.label + ' *' : value.label"
                            :name="value.name"
                            :rules="formValidations[value.name]"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            @blur="date = parseDate(dateFormatted)"
                            v-on="on"
                            readonly></v-text-field>
                          </template>
                          <v-date-picker v-if="showFieldDate" :locale="$i18n.locale" :first-day-of-week="1" v-model="date" no-title @input="menu1 = false"></v-date-picker>
                        </v-menu>
                        </div>

                        <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                          <v-textarea
                          v-if="value.name == 'special_customer_mark'"
                          :name="value.name"
                          :rows="'1'"
                          :label="value.mandatory ? value.label + ' *' : value.label"
                          :rules="formValidations[value.name]"
                          value="formValues[value.name]"
                          v-model="formValues[value.name]"></v-textarea>
                        </div>


                        <v-row>
                          <v-col class="title red--text py-0">Параметры посылки</v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4" :class="'py-0'">
                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                              <v-select
                              v-if="value.name == 'wtypeofcargo'"
                              :items="value.type.picklistValues"
                              item-text="label"
                              item-value="value"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :rules="formValidations[value.name]"
                              :name="value.name"></v-select>
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" :class="'py-0'">
                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                              <v-text-field
                              v-if="value.name == 'wpackageqty'"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :name="value.name"
                              :rules="formValidations[value.name]"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"></v-text-field>
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" :class="'py-0'">
                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                              <v-text-field
                              v-if="value.name == 'wweight'"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :name="value.name"
                              :rules="formValidations[value.name]"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"></v-text-field>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4" :class="'py-0'">
                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                              <v-text-field
                              v-if="value.name == 'wlength'"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :name="value.name"
                              :rules="formValidations[value.name]"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"></v-text-field>
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" :class="'py-0'">
                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                              <v-text-field
                              v-if="value.name == 'wwidth'"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :name="value.name"
                              :rules="formValidations[value.name]"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"></v-text-field>
                            </div>
                          </v-col>
                          <v-col cols="12" md="4" :class="'py-0'">
                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                              <v-text-field
                              v-if="value.name == 'wheight'"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :name="value.name"
                              :rules="formValidations[value.name]"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"></v-text-field>
                            </div>
                          </v-col>
                        </v-row>

                        <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                          <v-textarea
                          v-if="value.name == 'wparceldescription'"
                          :name="value.name"
                          :rows="'1'"
                          :label="value.mandatory ? value.label + ' *' : value.label"
                          :rules="formValidations[value.name]"
                          value="formValues[value.name]"
                          v-model="formValues[value.name]"></v-textarea>
                        </div>


                        <v-row>
                          <v-col class="title red--text py-0">Финансовые параметры</v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6" :class="'py-0'">
                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                              <v-select
                              v-if="value.name == 'wpaymentmethod'"
                              :items="value.type.picklistValues"
                              item-text="label"
                              item-value="value"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :rules="formValidations[value.name]"
                              :name="value.name"></v-select>
                            </div>
                          </v-col>
                          <v-col cols="12" md="6" :class="'py-0'">
                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                              <v-select
                              v-if="value.name == 'pay_recipient'"
                              :items="value.type.picklistValues"
                              item-text="label"
                              item-value="value"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :rules="formValidations[value.name]"
                              :name="value.name"></v-select>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="6" :class="'py-0'">
                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                              <v-text-field
                              v-if="value.name == 'wdeclaredvalue'"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :name="value.name"
                              :rules="formValidations[value.name]"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"
                              suffix="руб."></v-text-field>
                            </div>
                          </v-col>
                          <v-col cols="12" md="6" :class="'py-0'">
                            <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                              <v-text-field
                              v-if="value.name == 'wcod'"
                              :label="value.mandatory ? value.label + ' *' : value.label"
                              :name="value.name"
                              :rules="formValidations[value.name]"
                              value="formValues[value.name]"
                              v-model="formValues[value.name]"
                              suffix="руб."></v-text-field>
                            </div>
                          </v-col>
                        </v-row>


                        <div v-show='0 == 1'>
                          <div v-for="(value, key) in $store.getters.getEditableFields" :key="key">
                            <v-select
                            v-if="value.name == 'select_client_tarif'"
                            :items="value.type.picklistValues"
                            item-text="label"
                            item-value="value"
                            value="setTarif"
                            v-model="setTarif"
                            :label="value.mandatory ? value.label + ' *' : value.label"
                            :rules="formValidations[value.name]"
                            :name="value.name"></v-select>
                          </div>
                        </div>


                      </div>
                    </v-card>


                  </v-col>
                </v-row>
            </v-form>
        </v-row>


        <v-row class="ModuleFooter" justify="center">
        <!--
          <v-btn color="success" class="mr-4" @click="saveForm()" :disabled="!formValidity">{{$t('Save')}}</v-btn>
        -->
          <v-btn color="success" class="mr-4" @click="saveForm()">{{$t('Save')}}</v-btn>
          <v-btn color="red darken-1" text @click="resetForm()">{{$t('Cancel')}}</v-btn>
        </v-row>


        <notification></notification>
    </div>

</template>

<script>
import { mapState } from 'vuex';
import EventService from "@/services/EventService";
import Notification from "@/components/Notification";

export default {
	name: "WaybillEdit",
	components: {Notification},
	props: {
		id: {
			type: String,
			default: ''
		},
		formStartValues: {
			type: Object,
			// required: true,
			default: function () {
				return {}
			}
		}
	},
	data() {
		return {
			requiredAddressFields: ['fio', 'phone'],
			loading: false,
			message: '',
			notification: false,
			formValues: {},
			formValidations: {},
			maxValues: 190,
			minValues: 5,
			formValidity: false,
			date: new Date().toISOString().substr(0, 10),
			dateFormatted: '',
      showFieldDate: true,
			preChangedDate: '',
			menu1: false,
			searchModel: null,
			searchModelActive: false,
			searchRecipModel: null,
			searchRecipModelActive: false,
			isLoading: false,
			isLoadingRecip: false,
			search: null,
			searchRecip: null,
			entries: [],
			entriesRecip: [],
			mapping: [],
			blocks: null,
			dadataFields: [],
			dadataModels: {},
			dadataItems: {},
			dadataLocations: {'wrecipientcountry' : 'wrecipientaddress', 'wsendercountry' : 'wsenderaddress'},
      toggle_exclusive: 0,
      isHideBlockSenderAddress: 1,
      isHideBlockRecipientAddress: 1,
      addressManuallySenderShow: 1,
      addressManuallyRecipientShow: 1,
      addressManuallySenderRollUpShow: 0,
      addressManuallyRecipientRollUpShow: 0,
      fieldBlockAdressSender: ['wsendergeography', 'wsendercountry', 'wsenderregion', 'wsendercity', 'wsenderaddress'],
      fieldBlockAdressRecipient: ['wrecipientgeography', 'wrecipientcountry', 'wrecipientregion', 'wrecipientcity', 'wrecipientaddress'],
      fieldsChangeSenderRecipient: {'wsendercontact' : 'wrecipientcontact', 'wsendercompany' : 'wrecipientcompany', 'wsendercountry' : 'wrecipientcountry', 'wsendergeography' : 'wrecipientgeography',
                                    'wsenderregion' : 'wrecipientregion', 'wsendercity' : 'wrecipientcity', 'wsenderaddress' : 'wrecipientaddress', 'wsenderflat' : 'wrecipienflat',
                                    'wsenderphone' : 'wrecipientphone', 'wsenderinfo' : 'wrecipientinfo'
                                  },
      isMaskPhoneSender: 0,
      isMaskPhoneRecipient: 0,
      saveWaybill: true
		}
	},
	created() {
		this.loading = true;
		EventService.fetchBlocks('WayBill', null)
		.then(response => {
			/* eslint-disable no-console */
			console.log(response.data.result.record);
			/* eslint-enable no-console */
			this.blocks = response.data.result.record;
			this.blocks["Параметры посылки"][0] = "wtypeofcargo";
			this.blocks["Параметры посылки"][1] = "wlength";
			this.blocks["Параметры посылки"][2] = "wwidth";
			this.blocks["Параметры посылки"][3] = "wheight";
			this.blocks["Параметры посылки"][4] = "wpackageqty";
			this.blocks["Параметры посылки"][5] = "wweight";
			this.blocks["Параметры посылки"][6] = "wparceldescription";
		}).catch(error => {
			/* eslint-disable no-console */
			console.log(error);
			/* eslint-enable no-console */
		})
		.finally(() => this.loading = false);

    /* eslint-disable no-console */
    console.log(this.module.fields);
    /* eslint-enable no-console */

		for (var prop in this.module.fields) {
			var name = this.module.fields[prop].name;
			var value = this.module.fields[prop].type.defaultValue ? this.module.fields[prop].type.defaultValue : this.module.fields[prop].default;

      if(name == 'wsendercountry' || name == 'wsenderregion' || name == 'wsendergeography' || name == 'wsendercity' ||
      name == 'wsenderaddress'){
        this.module.fields[prop]['classGruop'] = 'inputAddressFieldSender';
      }
      if(name == 'wrecipientcountry' || name == 'wrecipientregion' || name == 'wrecipientgeography' || name == 'wrecipientcity' ||
      name == 'wrecipientaddress'){
        this.module.fields[prop]['classGruop'] = 'inputAddressFieldRecipient';
      }

			var validation = [];
			if (this.module.fields[prop].mandatory) {
				if(this.module.fields[prop].name != 'wrecipientregion' && this.module.fields[prop].name != 'wsenderregion'){
          const rule =
          v => !!v || this.$t('LBL_FIELD_REQUIRED');

          validation.push(rule);
        }
			}
			if (this.module.fields[prop].type.name != 'text') {
				let rule;
				if (this.module.fields[prop].type.name == 'currency') {
					const regexp = /^\$?([0-9]{1,3} ([0-9]{3} )*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/;
					rule = v => !v || regexp.test(v) || this.$t('LBL_INVALID_CHARACTERS');
				} else if (this.module.fields[prop].type.name == 'integer') {
					const regexp = /^\d+$/;
					rule = v => regexp.test(v) || this.$t('LBL_INVALID_CHARACTERS');
				} else if (this.module.fields[prop].type.name == 'phone' && this.module.fields[prop].name == 'wrecipientphone') {
					//const regexp = /^(\+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2})$/;
					//rule = v => regexp.test(v) || this.$t('LBL_INVALID_PHONE');
          rule = v => {
            var regexp1;
            var regexp2;
            if(this.isMaskPhoneRecipient == 1){
              regexp1 = /^(\+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2})$/;
              regexp2 = /^(\+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2}, \+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2})$/;
            } else if(this.isMaskPhoneRecipient == 2){
              regexp1 = /^(\+[0-9]{3}\([0-9]{3}\)[0-9]{2}-[0-9]{2}-[0-9]{2})$/;
              regexp2 = /^(\+[0-9]{3}\([0-9]{3}\)[0-9]{2}-[0-9]{2}-[0-9]{2}, \+[0-9]{3}\([0-9]{3}\)[0-9]{2}-[0-9]{2}-[0-9]{2})$/;
            } else {
              regexp1 = false;
              regexp1 = false;
            }
            if(regexp1 && regexp2){
              if(regexp1.test(v) || regexp2.test(v)){
                return true;
              } else {
                return this.$t('LBL_INVALID_PHONE');
              }
            } else {
              return true;
            }

          }

				} else if (this.module.fields[prop].type.name == 'phone' && this.module.fields[prop].name == 'wsenderphone') {
					//const regexp = /^(\+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2})$/;
					//rule = v => regexp.test(v) || this.$t('LBL_INVALID_PHONE');
          rule = v => {
            var regexp1;
            var regexp2;
            if(this.isMaskPhoneSender == 1){
              regexp1 = /^(\+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2})$/;
              regexp2 = /^(\+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2}, \+7\([0-9]{3}\)[0-9]{3}-[0-9]{2}-[0-9]{2})$/;
            } else if(this.isMaskPhoneSender == 2){
              regexp1 = /^(\+[0-9]{3}\([0-9]{3}\)[0-9]{2}-[0-9]{2}-[0-9]{2})$/;
              regexp2 = /^(\+[0-9]{3}\([0-9]{3}\)[0-9]{2}-[0-9]{2}-[0-9]{2}, \+[0-9]{3}\([0-9]{3}\)[0-9]{2}-[0-9]{2}-[0-9]{2})$/;
            } else {
              regexp1 = false;
              regexp1 = false;
            }
            if(regexp1 && regexp2){
              if(regexp1.test(v) || regexp2.test(v)){
                return true;
              } else {
                return this.$t('LBL_INVALID_PHONE');
              }
            } else {
              return true;
            }

          }

				} else {
					rule =
					v => (v || '').length <= this.maxValues ||
					(this.$t('LBL_MAX_CHARACTERS') + this.maxValues);
				}
				validation.push(rule);
			} else {
				// const rule =
				// v => (v || '').length > this.minValues ||
				// (this.$t('LBL_MIN_CHARACTERS') + this.minValues);

				// validation.push(rule);
			}

			if (this.module.fields[prop].name == 'wtakedate') {
				const rule =
				value => {
					var newDateSelect = this.parseDate(value);
					var today = new Date();
					today.setHours(0);
					today.setMinutes(0);
					today.setSeconds(0);
					today.setMilliseconds(0);
					if(new Date(newDateSelect) < today){
						//this.dateFormatted = this.formatDate(this.preChangedDate);
						//this.date = this.preChangedDate;
						return this.$t('LBL_DATE_CANNOT_BE_LATER_TODAY');
					} else {
						return true;
					}
				}

				validation.push(rule);
			}

			if (this.module.fields[prop].type.name == 'email') {
				const rule =
				value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalid e-mail.'
				}

				validation.push(rule);
			}

			if (this.module.fields[prop].editable) {
				this.$set(this.formValues, name, value);
				this.$set(this.formValidations, name, validation);
			}

		}
		this.$set(this.formValues, 'recipientId', null);
		this.$set(this.formValues, 'senderId', null);
		if (Object.keys(this.formStartValues).length !== 0) {
			// this.formValues = this.formStartValues;
			this.formValues = Object.assign({}, this.formStartValues);
		}
    this.getDateDefault();
		this.getDaDataFields();
	},
	mounted() {
		const self = this;
		if (Object.keys(this.formStartValues).length !== 0) {
			// this.formValues = this.formStartValues;
			this.formValues = Object.assign({}, this.formStartValues);
		}
		EventService.fetchMapping(this.module.name)
		.then(response => {
			this.mapping = response.data.result.data.standartAddressFields;
		})
		.catch(error => {
			/* eslint-disable no-console */
			console.log(error);
			/* eslint-enable no-console */
		})
		if (this.formValues.wsendercontact){
			EventService.searchAddressBook(this.formValues.wsendercontact)
			.then(function(response) {
				self.formValues.senderId = response.data.result.data[0].id;
			})
		}
		if (this.formValues.wrecipientcontact){
			EventService.searchAddressBook(this.formValues.wrecipientcontact)
			.then(function(response) {
				self.formValues.recipientId = response.data.result.data[0].id;
			})
		}
    this.$nextTick(function () {
      this.setPaddingTopModuleContent();
    });

	},
  updated: function () {
    this.$nextTick(function () {
      //После загрузки всех дочерних компонентов
    })
  },
	watch: {
		dadataModels(after) {
			if (after.data) {
				for (const name in after.data) {
					this.formValues[name] = after.data[name];
				}
			}
		},
		formStartValues: function() {
			if (Object.keys(this.formStartValues).length !== 0) {
				// this.formValues = this.formStartValues;
				this.formValues = Object.assign({}, this.formStartValues);
			}
		},
		date () {
			this.preChangedDate = this.parseDate(this.dateFormatted); //For validation and retun old select
			this.dateFormatted = this.formatDate(this.date)
		},

		searchRecip (val) {
      if(!val){
        return;
      }
			if(val.length < 4) {
				return;
			}
			// Items have already been requested
			if (this.isLoadingRecip) return;
			this.isLoadingRecip = true;
			// Lazily load input items
			EventService.searchAddressBook(val)
			.then(res => {
				this.entriesRecip = res.data.result.data;
			})
			.catch(err => {
				/* eslint-disable no-console */
				console.log(err);
				/* eslint-enable no-console */
			})
			.finally(() => (this.isLoadingRecip = false))
		},
		searchModel(model) {
			const self = this;
			for (let field in this.mapping[0]) {
				let fieldName = this.mapping[0][field];
				self.formValues[fieldName] = model.data[field];
			}
			this.formValues.senderId = model.id;
		},
		searchRecipModel(model) {
			const self = this;
			for (let field in this.mapping[1]) {
				let fieldName = this.mapping[1][field];
				self.formValues[fieldName] = model.data[field];
			}
			this.formValues.recipientId = model.id;
		}
	},
	methods: {
    getDateDefault: function(){
      if(this.formValues['wtakedate']){
        this.dateFormatted = this.formatDate(this.formValues['wtakedate']);
        this.showFieldDate = false;
				this.$delete(this.formValidations, 'wtakedate');
      } else {
        this.dateFormatted = this.formatDate(new Date().toISOString().substr(0, 10));
      }
    },
    changeDataSenderByRecipient: function(){
      var temp;
      for (var fieldNameSender in this.fieldsChangeSenderRecipient) {
        temp = this.formValues[fieldNameSender];
        this.formValues[fieldNameSender] = this.formValues[this.fieldsChangeSenderRecipient[fieldNameSender]];
        this.formValues[this.fieldsChangeSenderRecipient[fieldNameSender]] = temp;
      }
      var tempRulesPhone = this.formValidations['wsenderphone'];
      this.formValidations['wsenderphone'] = this.formValidations['wrecipientphone'];
      this.formValidations['wrecipientphone'] = tempRulesPhone;

      this.dadataModels = {};
			this.dadataItems = {};
    },
    handlerHideBlockAddress: function(nameGruopField, handler){
      if(nameGruopField == 'sender' && handler == 'show'){
        this.isHideBlockSenderAddress = 0;
        this.addressManuallySenderShow = 0;
        this.addressManuallySenderRollUpShow=1;
      } else if(nameGruopField == 'sender' && handler == 'hide'){
        this.isHideBlockSenderAddress = 1;
        this.addressManuallySenderShow = 1;
        this.addressManuallySenderRollUpShow = 0;
      }
      if(nameGruopField == 'recipient' && handler == 'show'){
        this.isHideBlockRecipientAddress = 0;
        this.addressManuallyRecipientShow = 0;
        this.addressManuallyRecipientRollUpShow=1;
      } else if(nameGruopField == 'recipient' && handler == 'hide'){
        this.isHideBlockRecipientAddress = 1;
        this.addressManuallyRecipientShow = 1;
        this.addressManuallyRecipientRollUpShow = 0;
      }
    },
    hideBlockAddress: function(nameGruop){
      if(this.isHideBlockSenderAddress == 1 && nameGruop == 'inputAddressFieldSender'){
        return 'hide';
      } else if(this.isHideBlockRecipientAddress == 1 && nameGruop == 'inputAddressFieldRecipient'){
        return 'hide';
      } else {
        return ' ';
      }
    },
    setPaddingTopModuleContent: function(){
      var topModuleHeader = document.getElementsByClassName('ModuleHeader')[0].offsetHeight;
      if(topModuleHeader > 0){
        document.getElementsByClassName('ModuleContent')[0].style.paddingTop = topModuleHeader;
      }
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
		resetForm() {
			this.$refs.createRecordForm.reset();
			this.$refs.createRecordForm.resetValidation();
      this.scrollToTop();
      this.$router.push({ name: 'WayBill', params: {} })
			//this.$emit('close');
		},
		saveForm() {
      this.$refs.createRecordForm.validate();
      if(!this.formValidity){
        this.handlerHideBlockAddress('sender', 'show');
        this.handlerHideBlockAddress('recipient', 'show');
        this.$store.commit('SET_NOTIFICATION', true);
				this.$store.commit('SET_MESSAGE', this.$t('Заполните обязательные поля.'));
        return false;
      }
      if(this.saveWaybill){
        this.saveWaybill = false;
      } else {
        return false;
      }

      var self = this;
      this.loading = true;
			this.saveAddressBook();

			var wtakedate = document.getElementsByName('wtakedate')[0].value;
			this.formValues.wtakedate = this.parseDate (wtakedate);

			const values = this.formValues;

      values.create_by_2021 = 1;
      values.select_client_tarif = document.getElementsByName('select_client_tarif')[0].value;

			delete values.recipientId;
			delete values.senderId;
			delete values.wpstatus;
			delete values.wrecivedby;
			delete values.house;
			delete values.block;
			delete values.block_type;
			for (const name in values) {
				for (const obj in this.module.fields) {
					if (this.module.fields[obj].name === name && !this.module.fields[obj].editable) {
						delete values[name];
					}
				}
			}

      if(!values.wrecipientregion){
        values.wrecipientregion = '???';
      }
      if(!values.wsenderregion){
        values.wsenderregion = '???';
      }

			if (typeof this.formValues.wcustomer == 'object') {
				values.wcustomer = this.formValues.wcustomer.value;
			} else {
				if (this.formValues.wcustomer.startsWith("11x")){
					values.wcustomer = this.formValues.wcustomer;
				} else {
					values.wcustomer = '11x' + this.formValues.wcustomer;
				}
			}
			EventService.saveRecord(this.module.name, values, this.id).then(function(response){
				self.create = false;
				self.loading = false;

				if (response.data.result.code) {
					self.$store.commit('SET_NOTIFICATION', true);
					self.$store.commit('SET_MESSAGE', response.data.result.message);
				} else {
					self.$store.commit('SET_NOTIFICATION', true);
					self.$store.commit('SET_MESSAGE', self.$t('Record added successfully'));
					self.$emit('close');
					if (!self.id || self.id == '') {
						self.$refs.createRecordForm.reset();
						self.$refs.createRecordForm.resetValidation();
						if (self.module.name == 'WayBill') {
							self.$store.commit('ADD_WAYBILL', response.data.result.record);
							self.$router.push({ name: 'waybill-show', params: { id: response.data.result.record.id } })
						} else {
							self.$store.commit('ADD_RECORD', response.data.result.record);
						}
					} else {
						self.$store.dispatch('fetchRecordModule', {module: self.module.name, id: self.id});
					}
				}
			})
			.catch(function(error){
        /* eslint-disable no-console */
        console.log(error);
        /* eslint-enable no-console */
				self.loading = false;
				self.$store.commit('SET_NOTIFICATION', true);
				self.$store.commit('SET_MESSAGE', self.$t('There was an error in adding record') + error.message);
			});

		},
		formatDate (date) {
			if (!date) return null;

			const [year, month, day] = date.split('-');
			return `${day}-${month}-${year}`;
		},
		parseDate (date) {
			if (!date) return null
				const [day, month, year] = date.split('-')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},
		saveAddressBook() {
			const self = this;
			this.mapping.forEach(function(map, index) {
				let record = {};
				let id = '';
				if (index > 0 && !self.searchRecipModelActive) {
					return;
				} else if(index < 1 && !self.searchModelActive) {
					return;
				}
				for (let field in map) {
					let crmField = map[field];
					if (field != 'account_id') {
						record[field] = self.formValues[crmField];
						if (self.requiredAddressFields.includes(field) && !self.formValues[crmField]) {
							return;
						}
					}
				}
				if (index > 0) {
					id = self.formValues.recipientId;
				} else {
					id = self.formValues.senderId;
				}
				EventService.saveRecord('AddressBook', record, id)
				.then(function(response) {
					/* eslint-disable no-console */
					console.log(response);
					/* eslint-enable no-console */
				})
				.catch(function(error) {
					/* eslint-disable no-console */
					console.log(error);
					/* eslint-enable no-console */
				})
			});
		},
		getDaDataFields () {
			const self = this
			EventService.fetchDaDataFields(this.module.name)
			.then(response => {
				this.dadataFields = response.data.result
				this.dadataFields.forEach(function(field) {
						// this.dadataModels[field] = null
						self.dadataItems[field] = []
					})
			})
			.catch(error => {
				/* eslint-disable no-console */
				console.log(error)
				/* eslint-enable no-console */
			})
		},
		searchDadata (field, e) {
			const value = e.target.value
			if (value.length > 3) {
				EventService.fetchDaDataSearch(this.module.name, value, field)
				.then(response => {
					const res = [];
					const result = response.data.result;
					for (const obj in result) {
						res.push({ name: obj, data: result[obj]})
					}
					const objRes = { ...this.dadataItems };
					objRes[field] = res;
					this.$set(this, 'dadataItems', objRes);

				})
				.catch(error => {
					/* eslint-disable no-console */
					console.log(error)
					/* eslint-enable no-console */
				})
			}
		},
    searchSender (e) {
      const val = e.target.value
      if(!val){
        return;
      }
      if(val.length < 4) {
        return;
      }
      // Items have already been requested
      if (this.isLoading) return;
      this.isLoading = true;
      // Lazily load input items
      EventService.searchAddressBook(val)
      .then(res => {
        this.$set(this, 'entries', res.data.result.data);
        //this.entries = res.data.result.data;
        /* eslint-disable no-console */
        console.log(this.entries);
        /* eslint-enable no-console */
      })
      .catch(err => {
        /* eslint-disable no-console */
        console.log(err);
        /* eslint-enable no-console */
      })
      .finally(() => (this.isLoading = false))
    },

    setIsMaskPhone: function (fieldName) {
      if(fieldName == 'wrecipientphone'){
        if(this.formValues['wrecipientcountry'] == 'Россия' || this.formValues['wrecipientcountry'] == 'РФ' || this.formValues['wrecipientcountry'] == 'Российская Федерация' || this.formValues['wrecipientcountry'] == 'РОССИЯ'){
          this.isMaskPhoneRecipient = 1;
        } else {
          //this.isMaskPhoneRecipient = 2;
          this.isMaskPhoneRecipient = 0;
        }
      } else if(fieldName == 'wsenderphone'){
        if(this.formValues['wsendercountry'] == 'Россия' || this.formValues['wsendercountry'] == 'РФ' || this.formValues['wsendercountry'] == 'Российская Федерация' || this.formValues['wsendercountry'] == 'РОССИЯ'){
          this.isMaskPhoneSender = 1;
        } else {
          //this.isMaskPhoneSender = 2;
          this.isMaskPhoneSender = 0;
        }
      }
    },

		chekNumberPhone: function (field, event) {
      var numberOld = event.target.value;
			var number = event.target.value;
      var numberCode;

      if(this.isMaskPhoneRecipient == 1 || this.isMaskPhoneSender == 1){
        numberCode = number.substr(1, 1);
        number = this.changeCodeCountryNumberPhone(number, numberCode, 1);
        numberCode = number.substr(19, 1);
        number = this.changeCodeCountryNumberPhone(number, numberCode, 2);
      }

      if(event.type == 'blur' || event.type == 'insertaddressbook'){
        number = this.chekNumberPhoneEventDelEndSymbol(number);
        if(field == 'wrecipientphone'){
          if(this.isMaskPhoneRecipient == 2){
            number = this.chekNumberPhoneEventDelEndSymbolNoRus(numberOld);
          }
          this.isMaskPhoneRecipient = 0;
        } else if(field == 'wsenderphone'){
          if(this.isMaskPhoneSender == 2){
            number = this.chekNumberPhoneEventDelEndSymbolNoRus(numberOld);
          }
          this.isMaskPhoneSender = 0;
        }
      }
      return number;
		},

    chekNumberPhoneEventDelEndSymbolNoRus: function (number) {
      if(number.substr(19, 1) == '+' && !number.substr(20, 1)){
        number = number.replace(', +', '');
      }
      if(number.substr(18, 1) == ' ' && !number.substr(19, 1)){
        number = number.replace(', ', '');
      }
      if(number.substr(17, 1) == ',' && !number.substr(18, 1)){
        number = number.replace(',', '');
      }
      return number;
    },

    chekNumberPhoneEventDelEndSymbol: function (number) {
      if(number.substr(18, 1) == '+' && !number.substr(19, 1)){
        number = number.replace(', +', '');
      }
      if(number.substr(17, 1) == ' ' && !number.substr(18, 1)){
        number = number.replace(', ', '');
      }
      if(number.substr(16, 1) == ',' && !number.substr(17, 1)){
        number = number.replace(',', '');
      }
      return number;
    },
    changeCodeCountryNumberPhone: function (number, numberCode, seq) {
      if(numberCode == 8){
        if(seq == 1){
          return number.substring(0, 1) + '7' + number.substring(2);
        } else if(seq == 2){
          return number.substring(0, 19) + '7' + number.substring(20);
        }

      } else if(numberCode != 8 && numberCode != 7 && numberCode > 0){
        var newNumber;
        if(seq == 1){
          newNumber = number.substring(0, 1) + '7' + number.substring(1);
        } else if(seq == 2){
          newNumber = number.substring(0, 19) + '7' + number.substring(19);
        }
        newNumber = newNumber.replace(/\(/, '');
        newNumber = newNumber.replace(/\)/, '');
        newNumber = newNumber.replace(/-/g, '');
        if(seq == 1){
          newNumber = newNumber.substring(0, 2) + '(' + newNumber.substring(2,5) + ')' + newNumber.substring(5,8)+ '-' + newNumber.substring(8,10)+ '-' + newNumber.substring(10);
        } else if(seq == 2){
          newNumber = newNumber.substring(0, 2) + '(' + newNumber.substring(2,5) + ')' + newNumber.substring(5,8)+ '-' + newNumber.substring(8,10)+ '-' + newNumber.substring(10);
        }
        return newNumber;
      } else {
        return number;
      }
    }
	},
	computed: {
    setTarif: function () {
      var tarif;
      if(this.toggle_exclusive == 0){
        tarif = 'Optima';
      } else if(this.toggle_exclusive == 1){
        tarif = 'Express';
      } else if(this.toggle_exclusive == 2){
        tarif = 'Praym';
      } else if(this.toggle_exclusive == 3){
        tarif = 'DayToDay';
      }
      return tarif;
    },

    getEntries: function () {
      return this.entries;
    },
    getMaskPhoneRecipient: function () {
      if(this.isMaskPhoneRecipient == 1){
        return '+#(###)###-##-##, +#(###)###-##-##';
      } else if(this.isMaskPhoneRecipient == 2){
        return '+###(###)##-##-##, +###(###)##-##-##';
      } else {
        return '';
      }
    },
    getMaskPhoneSender: function () {
      if(this.isMaskPhoneSender == 1){
        return '+#(###)###-##-##, +#(###)###-##-##';
      } else if(this.isMaskPhoneSender == 2){
        return '+###(###)##-##-##, +###(###)##-##-##';
      } else {
        return '';
      }
    },
    setAddressTextSender: function () {
      var arrAddressData = [];
      for (var fieldIndex in this.fieldBlockAdressSender) {
        if(this.formValues[this.fieldBlockAdressSender[fieldIndex]]){
          arrAddressData.push(this.formValues[this.fieldBlockAdressSender[fieldIndex]]);
        }
      }
      return arrAddressData.join(',  ');
    },
    setAddressTextRecipient: function () {
      var arrAddressData = [];
      for (var fieldIndex in this.fieldBlockAdressRecipient) {
        if(this.formValues[this.fieldBlockAdressRecipient[fieldIndex]]){
          arrAddressData.push(this.formValues[this.fieldBlockAdressRecipient[fieldIndex]]);
        }
      }
      return arrAddressData.join(',  ');
    },
		computedDateFormatted () {
			return this.formatDate(this.date)
		},
		items() {
			return this.entries;
		},
		getRecipientFieldLabel() {
			return this.formValues.wrecipientcontact ? this.formValues.wrecipientcontact : this.$t('Start typing');
		},
		getSenderLabel() {
			return this.formValues.wsendercontact ? this.formValues.wsendercontact : this.$t('Start typing');
		},
		...mapState(['module']),
	},
}
</script>

<style lang="css">

.ModuleHeader{
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: #f3f3f3;
  padding: 0 20;
}
.ModuleContent{
  padding: 0 20;
}

.ModuleFooter{
  padding: 10 20;
  margin-top: -8px;
  margin-bottom: 3px;
}

.width100p{
  width: 100%;
}

.ModuleHeaderButtons{
  height: 36px!important;
}
.ModuleHeader .v-btn-toggle>.v-btn.v-btn--active{
  background-color: #e53934e3;
  color: #ffffff!important;
}
.BlockContetnt{
  padding: 10 40;
}
.title{
  padding-left: 0;
}

.v-main__wrap.v-content__wrap{
  background-color: #f3f3f3;
}
.hide{
  display: none;
}
.cursorPointer{
  cursor: pointer;
}
.bold{
  font-weight: 500;
}
.textLink{
  font-size: 0.8rem;
}

.green-costum-text{
  color:#005c09!important;
}

</style>
